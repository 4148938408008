<template lang="pug">
.mobile-header
  q-header(elevated)
    q-toolbar
      .left
      .center
        router-link(to="/" aria-label="home")
          img(src="@/assets/header-logo.svg" alt="woosha_logo")
      .right
        q-btn(flat @click="drawerRight = !drawerRight" dense icon="r_menu" aria-label="menu_button")
  q-drawer(v-model="drawerRight" :width="$q.screen.width" :breakpoint="0" elevated side="right")
    .menu
      ul
        li
          router-link(to="/" @click="drawerRight = false" aria-label="home")
            .btn-en HOME
            .btn-ch 首&ensp;頁
        li
          router-link(to="/about" @click="drawerRight = false" aria-label="about")
            .btn-en ABOUT
            .btn-ch 關&ensp;於
        li
          router-link(to="/works?tag=all" @click="drawerRight = false" aria-label="works")
            .btn-en WORKS
            .btn-ch 專&ensp;案
        li
          router-link(to="/contact" @click="drawerRight = false" aria-label="contact")
            .btn-en CONTACT
            .btn-ch 聯&ensp;絡
        li
          router-link(to="/blog?tag=all" @click="drawerRight = false" aria-label="blog")
            .btn-en BLOG
            .btn-ch 部&ensp;落&ensp;格
//- .mobile-header
//-   .left-side
//-   .left-side
//-     router-link.button(to="/")
//-       img(src="/images/header-logo.svg")
//-   .right-side
//-     .header-button
//-       router-link.button(to="/about") ABOUT
//-       .divider /
//-       router-link.button(to="/works") WORKS
//-       .divider /
//-       router-link.button(to="/contact") CONTACT
//-       .divider /
//-       router-link.button(to="/blog") BLOG
//-     p WOOSHA DESIGN © 2022

</template>
<style lang="scss">
.mobile-header{
  display: none;
}
@media (max-width: 1024px) {
  .mobile-header{
    display: block;
    position: fixed;
    z-index: 9997;
    width: 100vw;
    .q-header{
      background-color: $green !important;
      height: 57px;
      .q-toolbar{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding: 0 !important;
        height: 100%;
        .left{
          width: 50px;
        }
        .center{
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          img{
            height: 20px;
          }
        }
        .right{
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #000;
          width: 50px;
          .q-btn{
            i{
              color: #fff;
            }
          }
        }
      }
    }
    .q-drawer{
      background-color: #000;
      height: calc(100vh - 57px); 
      height: calc(var(--vh, 1vh) * 100 - 57px);
      z-index: 9996 !important;
      .menu{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        ul{
          list-style: none;
          padding-left: 0;
          li{
            text-align: center;
            margin: 40px;
            a{
              color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
</style>
<script>
export default {
  name: 'Header',
  data () {
    return {
      drawerRight: false,
      list: [
        {
          // ch: '關於',
          en: 'ABOUT',
          type: false
        },
        {
          // ch: '服務',
          en: 'WORKS',
          type: false
        },
        {
          // ch: '專案',
          en: 'SERVICES',
          type: false
        },
        {
          // ch: '部落格',
          en: 'CONTACT',
          type: false
        },
        {
          // ch: '聯絡',
          en: 'BLOG',
          type: false
        }
      ]
    }
  },
  mounted () {
    this.setVh()
    window.addEventListener('resize', this.setVh)
  },
  methods: {
    headerClick (index) {
      this.list.forEach((e) => {
        e.type = false
      })
      this.list[index].type = true
    },
    setVh () {
      const vh = window.innerHeight * 0.01
      document.querySelector('.q-drawer').style.setProperty('--vh', `${vh}px`)
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.setVh)
  }
}
</script>
