
import './styles/quasar.scss'
import iconSet from 'quasar/icon-set/material-icons-round.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify
  },
  iconSet: iconSet
}
