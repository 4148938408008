<template lang="pug">
.mobile-main
  .header-bg
  .mobile-block
    canvas#canvas-bg
    .left
      p Refresh Your Lifestyle
    .right
      .top-shape
        img(src="@/assets/mobile-banner.svg" alt="woosha_logo")
      .bottom-shape
</template>

<script>
// @ is an alias to /src
// import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'MobileMain',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
    drawCanvas () {
      const c = document.querySelector('#canvas-bg')
      const ctx = c.getContext('2d')

      const block = document.querySelector('#canvas-bg')
      const width = block.offsetWidth
      const height = block.offsetHeight

      const center = {
        x: width / 2,
        y: height / 2
      }

      c.width = width
      c.height = height

      function windowResize () {
        const width = block.offsetWidth
        const height = block.offsetHeight
        c.width = width
        c.height = height
        ctx.restore()
        ctx.translate(center.x, center.y)
      }
      windowResize()
      window.addEventListener('resize', e => {
        windowResize()
        draw()
      })

      function draw () {
        ctx.strokeStyle = 'rgba(255, 255, 255, .3)'
        ctx.lineWidth = 1
        ctx.beginPath()
        ctx.moveTo(-width / 2, 0)
        ctx.lineTo(-width / 8.5, 0)
        ctx.lineTo(0, -height / 3)
        ctx.lineTo(-width / 3, -height / 3)
        ctx.lineTo(-width / 3, height / 3)
        ctx.lineTo(width / 10, height / 3)
        ctx.lineTo(width / 1.5, -height)
        ctx.stroke()
      }
      draw()
    }
  },
  mounted () {
    this.drawCanvas()
  }
}
</script>
<style lang="scss" scoped>
.mobile-main{
  display: none;
}
@media (max-width: 1024px) {
  .mobile-main{
    display: block;
    background-color: #000;
    width: 100%;
    height: 80vh;
    .header-bg{
      height: 57px;
    }
    .mobile-block{
      width: 100%;
      height: calc(100% - 57px);
      position: relative;
      display: flex;
      justify-content: space-between;
      #canvas-bg{
        position: absolute;
        left: 0;
        width: 100%;
        height: calc(100% - 5vh);
      }
      .left{
        width: 15%;
        position: relative;
        // border: solid 1px red;
        p{
          color: #fff;
          white-space: nowrap;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
          font-size: 2.5vh;
          letter-spacing: 1px;
        }
      }
      .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 85%;
        .top-shape{
          height: 5vh;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 67%;
          }
        }
        .bottom-shape{
          background-color: $green;
          height: 5vh;
          width: 100%;
          position: relative;
          &::after{
            content: "";
            background-color: $green;
            width: 5vh;
            height: 5vh;
            position: absolute;
            top: 0;
            transform: skewX(-45deg) translateX(-50%);
          }
        }
      }
    }
  }
}
</style>
