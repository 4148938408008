<template lang="pug">
.service
  .service-top
  .block
    .service-intro
      .top
        img(src="@/assets/move.svg" alt="logo")
        h2.service-name Our Service | 服務項目
      p 對於空間規劃有著獨到的見解，勇於嘗試各種各樣的施工方式，致力於創造美好的空間，無論是商空、辦公空間、住宅、建築等，從無到有，從設計到監工檢測，給予客戶穩紮的信賴感，是十年累積而成，是只為完工那一瞬呈現於眼前的專業與美好。
    .down-block#block_0
      .block-title {{ getServiceData[0].title }}
      .box-area
        q-responsive.box(:ratio="16/14")
          img(:src="getServiceData[0].imgUrls[0]" alt="commercial_1")
        q-responsive.box.move-box(:ratio="16/14")
          img(:src="getServiceData[0].imgUrls[1]" alt="commercial_2")
        q-responsive.box.hide(:ratio="16/14")
    .down-block#block_1
      .block-title {{ getServiceData[1].title }}
      .box-area
        q-responsive.box(:ratio="16/14")
          img(:src="getServiceData[1].imgUrls[0]" alt="office_1")
        q-responsive.box.hide(:ratio="16/14")
        q-responsive.box.down-box(:ratio="16/14")
          img(:src="getServiceData[0].imgUrls[2]" v-show="!firstChange" alt="commercial_3")
          img(:src="getServiceData[1].imgUrls[2]" v-show="firstChange" alt="office_3")
    .down-block#block_2
      .block-title {{ getServiceData[2].title }}
      .box-area
        q-responsive.box(:ratio="16/14")
          img(:src="getServiceData[2].imgUrls[0]" alt="residence_1")
        q-responsive.box.down-box(:ratio="16/14")
          img(:src="getServiceData[1].imgUrls[1]" v-show="!secondChange" alt="office_2")
          img(:src="getServiceData[2].imgUrls[1]" v-show="secondChange" alt="residence_2")
        q-responsive.box.hide(:ratio="16/14")
    .down-block#block_3
      .block-title {{ getServiceData[3].title }}
      .box-area
        q-responsive.box(:ratio="16/14")
          img(:src="getServiceData[3].imgUrls[0]" alt="architecture_1")
        q-responsive.box(:ratio="16/14")
          img(:src="getServiceData[3].imgUrls[1]" alt="architecture_2")
        q-responsive.box.down-box(:ratio="16/14")
          img(:src="getServiceData[2].imgUrls[2]" v-show="!thirdChange" alt="residence_3")
          img(:src="getServiceData[3].imgUrls[2]" v-show="thirdChange" alt="architecture_3")
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Service',
  data () {
    return {
      firstChange: false,
      secondChange: false,
      thirdChange: false,
      isRender: false,
      isGetData: false
    }
  },
  computed: {
    listenerTarget () {
      const { isRender, isGetData } = this
      return { isRender, isGetData }
    },
    getServiceData () {
      return this.$store.state.webContent.serviceSections
    }
  },
  watch: {
    getServiceData: {
      handler: function () {
        this.isGetData = true
      }
    },
    listenerTarget: {
      handler: function () {
        if (this.isRender && this.isGetData) {
          this.$nextTick(() => {
            this.serviceAnimation()
          })
        } else if (this.isRender && this.$store.state.webContent.serviceSections[0].title !== '') {
          this.$nextTick(() => {
            this.serviceAnimation()
          })
        }
      }
    }
    // isRender: {
    //   handler: function () {
    //     if (this.isRender && this.isGetData) {
    //       console.log('test')
    //       this.$nextTick(() => {
    //         this.serviceAnimation()
    //       })
    //     }
    //   }
    // }
  },
  methods: {
    serviceAnimation () {
      const downBlocks = gsap.utils.toArray('.down-block')
      const downBoxs = gsap.utils.toArray('.down-box')
      const serviceTl = gsap.timeline({})
      const blockHeight = document.querySelector('.down-block').offsetHeight
      const boxHeight = document.querySelector('.box-area').offsetHeight
      const minus = blockHeight - boxHeight
      const that = this

      gsap.timeline({
        scrollTrigger: {
          trigger: '.service',
          start: 'top 57px',
          end: 'bottom bottom',
          scrub: 1,
          pin: '.service-intro'
        }
      })

      for (let i = 0; i < downBlocks.length; i++) {
        if (i === 0) {
          serviceTl.from(downBoxs[i], {
            scrollTrigger: {
              trigger: downBlocks[i],
              start: '40% center',
              end: 'bottom center',
              scrub: 1,
              onEnter: function () {
                that.firstChange = true
              },
              onEnterBack: function () {
                that.firstChange = false
              }
            },
            y: -(boxHeight + minus),
            duration: 1,
            ease: 'none'
          })
        } else if (i === 1) {
          serviceTl.from(downBoxs[i], {
            scrollTrigger: {
              trigger: downBlocks[i],
              start: '40% center',
              end: 'bottom center',
              scrub: 1,
              onEnter: function () {
                that.secondChange = true
              },
              onEnterBack: function () {
                that.secondChange = false
              }
            },
            y: -(boxHeight + minus),
            duration: 1,
            ease: 'none'
          })
        } else if (i === 2) {
          serviceTl.from(downBoxs[i], {
            scrollTrigger: {
              trigger: downBlocks[i],
              start: '40% center',
              end: 'bottom center',
              scrub: 1,
              onEnter: function () {
                that.thirdChange = true
              },
              onEnterBack: function () {
                that.thirdChange = false
              }
            },
            y: -(boxHeight + minus),
            duration: 1,
            ease: 'none'
          })
        }
      }
    }
  },
  mounted () {
    // console.log('test', this.$store.state.webContent.serviceSections)
    this.isRender = true
    // this.serviceAnimation()
  }
}
</script>
<style lang="scss" scoped>
.service{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  // border: solid 3px red;
  // .service-top{
  //   height: 57px;
  //   background-color: $green;
  // }
  .block{
    padding: 0px 40px 10px 40px;
    position: relative;
    display: flex !important;
    flex-direction: column;
    background-color: $green;
    // background-color: #1F2823;
    .service-intro{
      padding: 20px 0px 35px 0px;
      background-color: $green;
      z-index: 9;

      .top{
        width: 100%;
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        // border: solid 3px red;
        img{
          height: 30px;
          margin-right: 5px;
        }
        h2{
          color: #000;
          font-size: 20px;
          margin: 0;
          line-height: 25px;
          // letter-spacing: 1px;
        }
      }
      p{
        padding-right: 20%;
        // border: solid 3px red;
        color: #000;
        font-size: 15px;
        letter-spacing: 1px;
        // letter-spacing: 2px;
        margin: 0;
      }
    }
    .down-block{
      // height: calc(100vh - 240px);
      display: flex;
      flex-direction: column;
      padding-bottom: 100px;
      .block-title{
        color: #000;
        font-size: 20px;
        // letter-spacing: 1px;
        margin-bottom: 10px;
      }
      .box-area{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .box{
          // background-color: rgba(255, 255, 255, 0.8);
          background-color: #000;
          flex-basis: 32%;
          border: solid 10px #1F2823;
          img{
            object-fit: cover;
          }
        }
        .hide{
          opacity: 0;
        }
      }
    }
  }
}
@media (min-width: 1500px) {
  .service{
    .block{
      padding: 0px 40px 10px 40px;
      .service-intro{
        // padding: 50px 0px 1.5% 0px;
        .top{
          img{
            height: 40px;
            margin-right: 5px;
          }
          h2{
            font-size: 24px;
            // letter-spacing: 1px;
          }
        }
        p{
          // letter-spacing: 1px;
          font-size: 18px;
          line-height: 30px;
        }
      }
      .down-block{
        padding-bottom: 100px;
        .block-title{
          font-size: 24px;
          // letter-spacing: 2px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .service{
    display: none;
  }
}
</style>
