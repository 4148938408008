import axios from 'axios'

const reqObj = axios.create({
  baseURL: '/api/v1',
  headers: {
    'Content-Type': 'application/json'
  }
})

/** 包裝、處理 axios 請求 */
function handleReq (p) {
  return new Promise((resolve, reject) => {
    p
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}

export function getWebsite () {
  return handleReq(reqObj.get('/web-content'))
}

export function getArticles () {
  return handleReq(reqObj.get('/articles', {
    params: {
      limit: 9999
    }
  }))
}

export function getProjects () {
  return handleReq(reqObj.get('/projects', {
    params: {
      limit: 9999
    }
  }))
}

export function getHomeProjects (data) {
  return handleReq(reqObj.get('/home-projects', {
    params: {
      limit: 9999
    }
  }))
}

export function postRequestForm (data) {
  return handleReq(reqObj.post('/request-form', data))
}
