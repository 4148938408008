<template lang="pug">
.blog
  .tag-block
    TagBtn(v-for="(item, index) in tags" :tag-data="item" @handleFilter="toggle" :current-tag="targetTag" :target-path="'blog'")
  .list-block
    div {{ outputLists?.data }}
    transition(name="fade" mode="out-in")
      Waterfall(:list="outputLists" :width="width < 560 ? width - 60 : 350" :key="reRender")
        template(#item="{ item, url, index }")
          template(v-if="item.title")
            router-link.card(:to="getEncodeURI(item.title)" aria-label="article")
              LazyImg(:url="getFirstImg (item) ? getFirstImg (item) : '/images/no-image.svg'" alt="article_image")
              h4.title {{item.title}}
              div.date {{item.dateTime}}
</template>

<script>
import TagBtn from '@/components/button/TagBtn.vue'
import { LazyImg, Waterfall } from 'vue-waterfall-plugin-next'
import 'vue-waterfall-plugin-next/dist/style.css'
import { uniqWith } from 'lodash'
import { useMeta } from 'quasar';

export default {
  name: 'Blog',
  components: {
    Waterfall,
    LazyImg,
    TagBtn
  },
  data () {
    return {
      reRender: 0,
      targetTag: {
        tw: '所有文章',
        en: 'all'
      },
      // dataList: [
      //   {
      //     _id: '',
      //     title: '',
      //     dateTime: '',
      //     style: [
      //       {
      //         tw: '',
      //         en: ''
      //       }
      //     ],
      //     content: '',
      //     imgs: [
      //       {
      //         url: '',
      //         visible: false
      //       }
      //     ]
      //   },
      //   {
      //     _id: '',
      //     title: '',
      //     dateTime: '',
      //     style: [
      //       {
      //         tw: '',
      //         en: ''
      //       }
      //     ],
      //     content: '',
      //     imgs: [
      //       {
      //         url: '',
      //         visible: false
      //       }
      //     ]
      //   }
      // ],
      search: '',
      width: 0
    }
  },
  computed: {
    dataList () {
      return this.$store.state.articles
    },
    tags () {
      const listArr = [
        {
          tw: '所有文章',
          en: 'all'
        }
      ]
      if (Array.isArray(this.dataList)) {
        this.dataList.forEach(e => {
          e.style.forEach(j => {
            listArr.push(j)
          })
        })
      }
      // console.log(listArr)
      // return listArr
      return uniqWith(listArr, (styleA, styleB) =>
        styleA.tw === styleB.tw && styleA.en === styleB.en
      )
    },
    getQuery(){
      return this.$route.query.tag
    },
    outputLists () {
      if (this.getQuery === 'all') {
        return this.dataList
      } else {
        const output = this.dataList.filter(e => e.style.some(j => j.en === this.getQuery))
        return output
      }
    }
  },
  watch: {
    getQuery: {
      immediate: true,
      handler(newQuery, oldQuery){
        console.log('query', newQuery)
        this.targetTag.en = newQuery
        this.reRender++
      }
    }
  },
  methods: {
    getFirstImg (list) {
      return list.imgs.filter(e => e.visible === true)?.[0]?.url
    },
    init () {
      // this.dataList = this.$store.state.posts
      // this.targetTag.en = this.$route.query.tag
      this.targetTag.en = this.getQuery
    },
    toggle (e) {
      console.log('eee', e)
      // this.targetTag = e
      this.reRender++
    },
    getWindowWidth () {
      this.width = window.innerWidth
    },
    getEncodeURI(title){
      const result = encodeURIComponent(title)
      return `/post/${result}`
    }
  },
  mounted () {
    this.$store.commit('LOADINGANIMATION', true)
    this.init()
    this.getWindowWidth()
    window.addEventListener('resize', this.getWindowWidth)
  },
  onUnmounted () {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  setup(){
    useMeta(() => ({
      title: '部落格 | Woosha Design 舞夏設計',
      // description: '部落格 | Woosha Design 舞夏設計'
      meta: {
        description: { name: 'description', content: '部落格 | Woosha Design 舞夏設計' }
      }
    }))
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active{
  transition: opacity .5s;
}
.fade-enter-from, .fade-leave-to{
  opacity: 0;
}
.fade-enter-to, .fade-leave-from{
  opacity: 1;
}
.blog{
  padding-top: 100px;
  min-height: calc(100vh - 85px);
  padding: 100px 40px 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  .tag-block{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 15px;
    // max-width: 1080px;
    flex-wrap: wrap;
    // gap: 10px;
    width: 100%;
  }
  .list-block{
    // border: solid 3px #000;
    // background-color: rgba(0,0,0,.2);
    padding: 0px 0px;
    width: 100%;
    // min-height: 50px;
    // min-height: calc(100vh - 50px);
  }
  .waterfall-list{
    background-color: rgba(0, 0, 0, 0);
    .card{
      color: #000;
      text-decoration: none;
      padding: 0px 3px;
      display: flex;
      flex-direction: column;
      // border: solid 3px red;
      .lazy__box{
        border-radius: 2px;
        overflow: hidden;
      }
      .title{
        font-size: 18px;
        line-height: 20px;
        margin: 10px 0px 3px 0px;
      }
      .date{
        font-size: 14px;
        padding-bottom: 12px;
        opacity: .6;
      }
    }
  }
}
@media (max-width: 1024px) {
  .blog{
    padding: 100px 5% 30px 5%;
    .tag-block{
      justify-content: flex-start;
    }
  }
}
@media (max-width: 640px) {
  .blog{
    .tag-block{
      gap: 10px;
    }
  }
}
@media (max-width: 560px) {
  .blog{
    .list-block{
      .waterfall-list{
        .card{
          padding: 0px;
        }
      }
    }
  }
}
</style>
