<template lang="pug">
.tag-button
  router-link(:to="`/${targetPath}?tag=${tagData.en}`" v-if="targetPath === 'works'" class="works-btn" aria-label="project")
    .en-type(:class="{active : tagData.en === currentTag.en}" @click="handleToggle(tagData)") {{ tagData.en.toUpperCase() }}
    .ch-type(@click="handleToggle(tagData)") {{ tagData.tw }}
  router-link(:to="`/${targetPath}?tag=${tagData.en}`" v-if="targetPath === 'works'" class="mobile-works-btn" aria-label="project")
    .en-type(:class="{active : tagData.en === currentTag.en}" @click="handleToggle(tagData)") {{ tagData.tw }}
  router-link(:to="`/${targetPath}?tag=${tagData.en}`" v-if="targetPath === 'blog'" class="blog-btn" aria-label="article")
    .en-type(:class="{active : tagData.en === currentTag.en}" @click="handleToggle(tagData)") {{ tagData.tw }}
</template>
<script>

export default {
  name: 'TagBtn',
  props: ['tagData', 'currentTag', 'targetPath'],
  methods: {
    handleToggle (val) {
      this.$emit('handleFilter', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.active{
  background-color: #000;
  color: #fff;
}
.tag-button{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
  padding: 0px 7px;
  // border: solid 3px red;
  // width: calc(20% - 8px);
  a{
    color: #000;
    text-decoration: none;
  }
  .en-type{
    // border: solid 3px;
    padding: 2px 15px;
    position: relative;
    margin: 4px 0px;
    cursor: pointer;
    transition-duration: .5s;
    text-align: center;
    &::before{
      content: '';
      position: absolute;
      box-sizing: border-box;
      height: 0%;
      width: 0%;
      left: 0;
      top: 0;
      visibility: hidden;
      transition: width 0.2s ease-in .1s, height .1s ease-in, visibility 0s .3s;
      border-top: solid 2px #000;
      border-right: solid 2px #000;
    }
    &::after{
      content: '';
      position: absolute;
      box-sizing: border-box;
      height: 0%;
      width: 0%;
      right: 0;
      bottom: 0;
      visibility: hidden;
      transition: width 0.2s ease-in .1s, height .1s ease-in, visibility 0s .3s;
      border-bottom: solid 2px #000;
      border-left: solid 2px #000;
    }
    &:hover{
      &::before{
        height: 100%;
        width: 100%;
        visibility: visible;
        transition: width 0.2s ease-in, height .1s ease-in .2s;
      }
      &::after{
        height: 100%;
        width: 100%;
        visibility: visible;
        transition: width 0.2s ease-in .3s, height .1s ease-in .5s, visibility 0s .3s;
      }
    }
  }
  .ch-type{
    text-align: center;
    font-size: 14px;
    letter-spacing: 2px;
    cursor: pointer;
  }
  .blog-btn{
    .en-type{
      padding: 3px 18px;
    }
  }
  .mobile-works-btn{
    display: none;
  } 
}
@media (max-width: 1024px) {
  .tag-button{
    padding: 0px 10px;
  }
  // .tag-button{
  //   width: calc(25% - 8px);
  // }
}
@media (max-width: 860px) {
  // .tag-button{
  //   width: calc(33.33% - 7px);
  // }
}
@media (max-width: 640px) {
  .tag-button{
    // width: calc(50% - 10px);
    padding: 0px 0px;
    margin-bottom: 0px;
    .en-type{
      border: solid 2px #000;
      padding: 3px 15px;
      margin: 0px;
    }
    .ch-type{
      display: none;
    }
    .mobile-works-btn{
      display: block;
    } 
  }
  .tag-button:first-child{
    width: 100%;
  }
  .works-btn{
    display: none;
  }

}
</style>
