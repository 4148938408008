<template lang="pug">
.project
  .header-bg
  .block
    .project-top
      img(src="@/assets/move.svg" alt="logo")
      h2.project-name Our Projects | 專案案例
    .project-block(v-for="(item) in getProjectData")
      .inner-left
        .title-block
          p Name | {{ item.name === '' ? item.enName : item.name}}
        .img-block
          template(v-for="imgData in item.imgs")
            img(:src="imgData.url" v-if="imgData.visible")
      .inner-right
        .type-block
          p Type | {{ getTypeText(item.style) }}
        .area-block
          p Area | {{ item.county }}
        .intro-block
          q-scroll-area.scroll-bar(:thumb-style="thumbStyle")
            p(v-html="item.description" v-if="item.description !== ''")
            p(v-else) {{ item.name === '' ? item.enName : item.name }}
        .scroll-bottom
    //- .project-block
    //-   .inner-left
    //-     .title-block
    //-       p Name | 健身時代
    //-     .img-block
    //-       img(src="/images/project1-1.jpeg")
    //-       img(src="/images/project1-2.jpeg")
    //-       img(src="/images/project1-3.jpeg")
    //-       img(src="/images/project1-1.jpeg")
    //-   .inner-right
    //-     .type-block
    //-       p Type | 商業空間設計
    //-     .area-block
    //-       p Area | 台中大里
    //-     .intro-block
    //-       q-scroll-area.scroll-bar(:thumb-style="thumbStyle")
    //-         p 空間基底用沉穩的色調
    //-           br
    //-           | 再用企業色和輔助圖型點綴
    //-           br
    //-           | 讓其整體空間成熟中帶活力振奮的感覺
    //-           br
    //-           | -
    //-           br
    //-           | 空間內有一個主視覺牆
    //-           br
    //-           | 像是岩壁鑿出來的字樣
    //-           br
    //-           | 象徵一個好的身形
    //-           br
    //-           | 要用時間和毅力
    //-           br
    //-           | 加上正確的健身觀念
    //-           br
    //-           | 來一點一滴雕塑
    //-           br
    //-           | -
    //-           br
    //-           | 這是一個只要用心就能實現夢想的健身時代
    //-           br
    //-           | 來一點一滴雕塑
    //-           br
    //-           | -
    //-           br
    //-           | 這是一個只要用心就能實現夢想的健身時代
    //-     .scroll-bottom
.more
  router-link.button(to="/works?tag=all")
    p ALL PROJECTS
    .arrow
</template>

<script>
import { debounce } from 'lodash-es'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'HomeProjects',
  data () {
    return {
      debouncedResize: undefined,
      thumbStyle: {
        right: '4px',
        borderRadius: '5px',
        background: '#fff',
        width: '5px',
        opacity: 1
      },
      isRender: false,
      isGetData: false
    }
  },
  computed: {
    getProjectData () {
      // const list = [
      //   this.$store.state.projects.find(j => j.style.some(k => k.en.toUpperCase() === 'COMMERCIAL')),
      //   this.$store.state.projects.find(j => j.style.some(k => k.en.toUpperCase() === 'RESIDENCE')),
      //   this.$store.state.projects.find(j => j.style.some(k => k.en.toUpperCase() === 'OFFICE')),
      //   this.$store.state.projects.find(j => j.style.some(k => k.en.toUpperCase() === 'ARCHITECTURE'))
      // ].filter(e => e)
      const list = this.$store.state.homeProjects
      return list
    },
    listenerTarget () {
      const { isRender, isGetData } = this
      return { isRender, isGetData }
    }
  },
  watch: {
    getProjectData: {
      handler: function () {
        this.isGetData = true
      }
    },
    listenerTarget: {
      handler: function () {
        if (this.isRender && this.isGetData) {
          this.$nextTick(() => {
            this.getImgLoaded()
          })
        } else if (this.isRender && this.$store.state.projects.length > 1) {
          this.$nextTick(() => {
            this.getImgLoaded()
          })
        }
      }
    }
  },
  methods: {
    getTypeText (item) {
      let text = ''
      item.forEach(e => {
        text += e.tw
      })
      return text
    },
    getImgLoaded () {
      const that = this
      const images = document.querySelector('.project').querySelectorAll('img')
      const promises = Array.prototype.slice.call(images).map(node => {
        return new Promise((resolve, reject) => {
          const loadImg = new Image()
          loadImg.src = node.src
          loadImg.onload = () => {
            resolve(node)
          }
        })
      })
      Promise.all(promises).then(results => {
        that.projectAnimation()
      }).catch(e => {
        console.error(e)
      })
    },
    projectAnimation () {
      const projectBlocks = gsap.utils.toArray('.project-block')
      const innerRight = gsap.utils.toArray('.inner-right')
      const titleBlocks = gsap.utils.toArray('.title-block')
      // gsap.timeline({
      //   scrollTrigger: {
      //     trigger: '.project',
      //     start: 'top 57px',
      //     end: 'bottom bottom',
      //     scrub: 1,
      //     pin: '.project-top'
      //   }
      // })
      ScrollTrigger.create({
        trigger: '.project',
        start: 'top 57px',
        end: `bottom 100%-=${window.innerHeight - document.querySelector('.inner-right').offsetHeight - 167}px`,
        scrub: 1,
        pin: '.project-top'
      })
      for (let i = 0; i < projectBlocks.length; i++) {
        gsap.timeline({
          scrollTrigger: {
            trigger: projectBlocks[i],
            // start: `top 0%+=${document.querySelector('.project-top').offsetHeight + document.querySelector('.header-bg').offsetHeight}px`,
            start: `top 0%+=${document.querySelector('.project-top').offsetHeight + 57}px`,
            end: `bottom 100%-=${window.innerHeight - document.querySelector('.inner-right').offsetHeight - 167}px`,
            scrub: 1,
            pin: innerRight[i]
          }
        })
        gsap.timeline({
          scrollTrigger: {
            trigger: projectBlocks[i],
            // start: `top 0%+=${document.querySelector('.project-top').offsetHeight + document.querySelector('.header-bg').offsetHeight}px`,
            start: `top 0%+=${document.querySelector('.project-top').offsetHeight + 57}px`,
            end: `bottom 100%-=${window.innerHeight - document.querySelector('.inner-right').offsetHeight - 167}px`,
            scrub: 1,
            pin: titleBlocks[i]
          }
        })
      }
    },
    handleProjectResize () {
      const width = window.innerWidth
      if (width > 1024) {
        window.location.reload()
      }
      this.getImgLoaded()
    },
    handleDebounceProjectResize () {
      debounce(this.handleProjectResize, 3000)
    }
  },
  created () {
    this.debouncedResize = debounce(this.handleProjectResize, 1000)
  },
  mounted () {
    this.isRender = true
    window.addEventListener('resize', this.debouncedResize)
  },
  unmounted () {
    window.removeEventListener('resize', this.debouncedResize)
    this.debouncedResize.cancel
  }
}
</script>
<style lang="scss" scoped>
.project{
  width: 100%;
  display: flex;
  flex-direction: column;
  // .header-bg{
  //   height: 57px;
  //   background-color: $green;
  // }
  .block{
    width: 100%;
    padding: 0px 40px;
    position: relative;
    display: flex !important;
    flex-direction: column;
    background-color: $green;
    // background-color: #1F2823;
    .project-top{
      width: 100%;
      display: flex;
      align-items: center;
      padding: 20px 0px 20px 0px;
      background-color: $green;
      z-index: 999;
      position: relative;
      // border: solid 3px red;
      &::before{
        content: '';
        position: absolute;
        top: -1px;
        width: 100%;
        height: 1px;
        background-color: $green;
      }
      img{
        height: 30px;
        margin-right: 5px;
      }
      h2{
        color: #000;
        font-size: 20px;
        margin: 0;
        line-height: 20px;
        // letter-spacing: 1px;
      }
    }
    .project-block{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: stretch;
      margin-bottom: 60px;
      background-color: $green;
      .inner-left{
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1.5;
        margin-right: 2%;
        .title-block{
          color: #000;
          display: flex;
          align-items: center;
          background-color: $green;
          position: relative;
          &::before{
            content: '';
            width: calc(100% + 6px);
            height: calc(100%);
            top: -3px;
            background-color: $green;
            position: absolute;
            z-index: 98;
          }
          p{
            border-top: solid 2px #000;
            border-bottom: solid 2px #000;
            padding: 10px 0px;
            margin: 0;
            font-size: 16px;
            width: 100%;
            z-index: 99;
          }
          // &::before{
          //   content: '';
          //   width: 100%;
          //   height: 3px;
          //   top: -3px;
          //   background-color: $green;
          //   position: absolute;
          //   z-index: 98;
          //   border: solid 3px red;
          // }
        }
        .img-block{
          margin: 0px 0px;
          background-color: #000;
          padding: 12px 12px 0px 12px;
          margin-bottom: 0px;
          img{
            width: 100%;
            margin-bottom: 12px;
          }
        }
      }
      .inner-right{
        flex: 1;
        color: #000;
        display: flex;
        flex-direction: column;
        height: 100%;
        .type-block{
          border-top: solid 2px #000;
          border-bottom: solid 2px #000;
          p{
            margin: 10px 0px;
            font-size: 16px;
          }
        }
        .area-block{
          border-bottom: solid 2px #000;
          p{
            margin: 10px 0px;
            font-size: 16px;
          }
        }
        .intro-block{
          margin: 20px 0px;
          .scroll-bar{
            height: calc(100vh - 300px);
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 5px;
            p{
              margin: 20px 25px;
              font-size: 16px;
              line-height: 30px;
              color: #fff;
              white-space: pre-line;
            }
          }
        }
        .scroll-bottom{
          border-bottom: solid 2px #000;
        }
      }
    }
    .start{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 30px;
      .title{
        text-align: center;
        font-size: 5vw;
        letter-spacing: 0px;
        margin-bottom: 40px;
      }
    }
  }
}
.more{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .button{
    display: inline-flex;
    background-color: #000;
    padding: 15px 40px;
    align-items: flex-end;
    text-decoration: none;
    p{
      color: #fff;
      font-size: 24px;
      line-height: 24px;
      margin: 0;
      margin-right: 10px;
      letter-spacing: 1px;
    }
    .arrow{
      border-bottom: solid 2px #fff;
      width: 40px;
      border-right: solid 2px #fff;
      height: 12px;
      transform: skewX(30deg);
    }
  }
}
@media (min-width: 1500px) {
  .project{
    .block{
      .project-top{
        img{
          height: 40px;
          margin-right: 5px;
        }
        h2{
          font-size: 24px;
        }
      }
      .project-block{
        .inner-left{
          .title-block{
            p{
              font-size: 16px;
              letter-spacing: 1px;
            }
          }
        }
        .inner-right{
          .type-block{
            p{
              font-size: 16px;
              letter-spacing: 1px;
            }
          }
          .area-block{
            p{
              font-size: 16px;
              letter-spacing: 1px;
            }
          }
          .intro-block{
            .scroll-bar{
              p{
                letter-spacing: 1px;
                font-size: 16px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .project{
    display: none;
  }
  .more{
    padding: 0px 5%;
    display: none;
    .button{
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px;
      p{
        font-size: 18px;
      }
    }
  }
}
</style>
