<template lang="pug">
div
  page404
</template>
  
<script>
import { useMeta } from 'quasar';
import page404 from '../components/404.vue';

export default {
  name: '404',
  components: {
    page404
  },
  mounted () {
    if(!!document.head.querySelector("meta[name=prerender-status-code]")) return;
    const meta = document.createElement('meta');
    meta.name = "prerender-status-code"
    meta.content ="404"
    document.head.appendChild(meta);
  },
  setup(){
    useMeta(() => ({
      title: '404 Not Found'
    }))
  }
}
</script>
<style lang="scss" scoped>
</style>