<template lang="pug">
.works
  .header-bg
  .block
    .top
      img(src="@/assets/move.svg" alt="woosha_logo")
      h2.about-name Space Design | 空間設計作品
    .list-block
      .tag-block
        TagBtn(v-for="(item, index) in tags" :tag-data="item" @handleFilter="toggle" :current-tag="targetTag" :target-path="'works'")
      .show-block
        transition-group(name="fade")
          .list(v-for="(list, index) in currentLists" :key="list._id")
            router-link.card(:to="`/project/${list.name === '' ? list.enName : list.name}`" aria-label="project")
              q-responsive.pic(:ratio="4/3")
                q-img(:src="getFirstImg(list)" alt="project")
              .name {{ list.name === '' ? list.enName : list.name }}
//- .space
</template>

<script>
import TagBtn from '@/components/button/TagBtn.vue';
import { uniqWith } from 'lodash';
import { useMeta } from 'quasar';
export default {
  name: 'Works',
  components: {
    TagBtn
  },
  data () {
    return {
      show: true,
      targetTag: {
        tw: '所有專案',
        en: 'all'
      }
    }
  },
  computed: {
    dataList () {
      return this.$store.state.projects
    },
    currentLists () {
      if (this.targetTag.en === 'all') {
        console.log(this.dataList)
        return this.dataList
      } else {
        const output = this.dataList.filter(e => e.style.some(j => j.en === this.targetTag.en))
        console.log(output)
        return output
      }
    },
    tags () {
      const listArr = [
        {
          tw: '所有專案',
          en: 'all'
        }
      ]
      this.dataList.forEach(e => {
        e.style.forEach(j => {
          listArr.push(j)
        })
      })
      return uniqWith(listArr, (styleA, styleB) =>
        styleA.tw === styleB.tw && styleA.en === styleB.en
      )
    },
    getQuery(){
      return this.$route.query.tag
    }
  },
  methods: {
    getFirstImg (list) {
      if(list.imgs.filter(e => e.visible === true)?.[0]?.url) {
        return list.imgs.filter(e => e.visible === true)?.[0]?.url
      }else {
        return '/images/no-image.svg'
      }
    },
    init () {
      this.targetTag.en = this.$route.query.tag
    }
    // toggle (e) {
    //   this.targetTag = e
    // }
  },
  watch: {
    getQuery: {
      immediate: true,
      handler(newQuery, oldQuery){
        console.log('query', newQuery)
        this.targetTag.en = newQuery
      }
    }
  },
  mounted () {
    this.$store.commit('LOADINGANIMATION', true)
    this.init()
  },
  setup(){
    useMeta(() => ({
      title: '設計作品 | Woosha Design 舞夏設計',
      // description: '設計作品 | Woosha Design 舞夏設計'
      meta: {
        description: { name: 'description', content: '設計作品 | Woosha Design 舞夏設計' }
      }
    }))
  }
}
</script>

<style lang="scss" scoped>
%clearfix {
	*zoom: 1;
	&:before, &:after {
		display: table;
		line-height: 0;
		content: "";
	}
	&:after {
		clear: both;
	}
}
.click{
  background-color: #000;
  color: #fff;
}
.works{
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 85px);
  background-color: #fff;
  .header-bg{
    height: 57px;
  }
  .block{
    padding: 30px 40px;
    position: relative;
    display: flex !important;
    flex-direction: column;
    .top{
      width: 100%;
      // display: flex;
      // padding: 0px 0px 30px 0px;
      margin-bottom: 30px;
      // align-items: center;
      @extend %clearfix;
      img{
        height: 23px;
        width: 23px;
        margin-right: 8px;
        float: left;
        margin-top: 5px;
      }
      h2{
        color: #000;
        font-size: 20px;
        margin: 0;
        line-height: 30px;
      }
    }
    .list-block{
      // border: solid 3px #000;
      // background-color: rgba(0,0,0,.2);
      // padding: 30px;
      // min-height: 50px;
      padding: 0px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: calc(100vh - 320px);
      .tag-block{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 18px;
        width: 100%;
        margin-bottom: 15px;
        // gap: 10px;
      }
      .show-block{
        width: 100%;
        // border: solid 3px red;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .list{
          width: calc(25% - 15px);
          // border: solid 2px #000;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .card{
            color: #000;
            text-decoration: none;
            margin-bottom: 20px;
            height: 100%;
            .pic{
              width: 100%;
              background-color: #000;
              margin-bottom: 15px;
              border-radius: 2px;
              overflow: hidden;
              img{
                object-fit: cover;
                min-height: 100%;
                min-width: 100%;
              }
            }
            .name{
              font-size: 18px;
              text-align: center;
              margin-bottom: 5px;
            }
            .category{
              font-size: 16px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .works{
    .block{
      padding: 30px 5%;
      .list-block{
        .tag-block{
          justify-content: flex-start;
        }
        .show-block{
          .list{
            width: calc(33.33% - 14px);
          }
        }
      }
    }
  }
}
@media (max-width: 840px) {
  .works{
    .block{
      .list-block{
        .show-block{
          .list{
            width: calc(50% - 10px);
          }
        }
      }
    }
  }
}
@media (max-width: 640px) {
  .works{
    .block{
      .list-block{
        padding: 0;
        border: none;
        background-color: rgba(0, 0, 0, 0);
        .tag-block{
          gap: 10px;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .works{
    .block{
      .list-block{
        .show-block{
          .list{
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
