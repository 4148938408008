<template lang="pug">
.about-intro
  .header-bg
  .block
    .title
      img(src="@/assets/move.svg" alt="woosha_logo")
      h2.about-name About Woosha | 關於舞夏
    article.intro
      p.company-intro(v-html="getCompanyIntro[0]")
      p.slogan(v-html="getCompanyIntro[1]")
    .member
      .title
        img(src="@/assets/move.svg" alt="woosha_logo")
        h2.about-name About Members | 舞夏成員
      p {{ getMemberIntro }}
      .member-block
        .member-card(v-for="(member, index) in getMemberData" key="index" :id="`member_${index}`")
          q-responsive.member(v-bind:class="{ memberStyle: member.main != ''}"  :ratio="16/15")
            .img
              img(:src="member.imgUrl" alt="member")
          .member-intro
            p.main {{ member.jobTitle }}
            p.name {{ member.name }}
      AboutCarousel(:parent-msg="getMemberData")
    .relation
      .card(v-for="company in getRelationCompany")
        h6.name {{ company.name }}
        .bottom
          p 統一編號 : {{ company.vatNumber }}
          p {{ company.businessLicense }}
//- .space
</template>

<script>
import AboutCarousel from './AboutCarousel.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'AboutIntro',
  components: {
    AboutCarousel
  },
  data () {
    return {
      index: 0,
      categoryLength: 0,
      memberGroup: []
    }
  },
  computed: {
    // members: {
    //   get () {
    //     const that = this
    //     return that.getMember()
    //   }
    // }
    getCompanyIntro () {
      return this.$store.state.webContent.mainInfos
    },
    getMemberIntro () {
      return this.$store.state.webContent.memberInfo
    },
    getMemberData () {
      return this.$store.state.webContent.memberList.filter(e => e.visible !== false)
    },
    getRelationCompany () {
      return this.$store.state.webContent.affiliateEnterprises.filter(e => e.visible !== false)
    }
  },
  methods: {
    // getMember () {
    //   const that = this
    //   const data = [
    //     {
    //       main: '總監',
    //       name: '林冠宇 | Gary',
    //       img: '/images/1.png'
    //     },
    //     {
    //       main: '設計總監',
    //       name: '楊博勛 | Uto',
    //       img: '/images/2.png'
    //     },
    //     {
    //       main: '副總',
    //       name: '王明志 | Ｍick',
    //       img: '/images/3.png'
    //     },
    //     {
    //       main: '建築師',
    //       name: '陳彥宏 | Alvis',
    //       img: '/images/4.png'
    //     },
    //     {
    //       main: '海外業務經理',
    //       name: '林聯忠 Larry',
    //       img: '/images/5.png'
    //     }
    //   ]
    //   function group (array, groupLength) {
    //     let index = 0
    //     const newArray = []
    //     while (index < array.length) {
    //       newArray.push(array.slice(index, index += groupLength))
    //     }
    //     return newArray
    //   }
    //   const category = group(data, 4)
    //   that.memberGroup = category
    //   that.memberGroup.forEach(e => {
    //     for (let i = 0; i < e.length; i++) {
    //       if (e.length < 4) {
    //         e.push({
    //           main: '',
    //           name: '',
    //           img: ''
    //         })
    //       }
    //     }
    //   })
    //   that.categoryLength = category.length
    //   return category[that.index]
    // },
    // memberAnimation () {
    //   const that = this
    //   const distanceX = (e) => {
    //     const distance = document.querySelector(`#member_${e}`).offsetLeft
    //     const w = document.querySelector(`#member_${e}`).offsetWidth
    //     const basic = document.querySelector('.member-block').offsetWidth / 2
    //     const move = basic - (distance - 40) - (w / 2)
    //     return move
    //   }
    //   const distanceY = (e) => {
    //     const distance = document.querySelector(`#member_${e}`).offsetLeft
    //     const w = document.querySelector(`#member_${e}`).offsetWidth
    //     const basic = document.querySelector('.member-block').offsetWidth / 2
    //     const move = -1 * (basic - (distance - 40) - (w / 2))
    //     console.log('move', e, move)
    //     return move
    //   }
    //   const delay = (e) => {
    //     if (e === 0 || e === 3) {
    //       return 0
    //     } else if (e > 0 || e < 3) {
    //       return 0.1
    //     }
    //   }
    //   const delayTwo = (e) => {
    //     if (e === 0 || e === 3) {
    //       return 0.1
    //     } else if (e > 0 || e < 3) {
    //       return 0
    //     }
    //   }
    //   const duration = (e) => {
    //     if (e === 0 || e === 3) {
    //       return 2
    //     } else if (e > 0 || e < 3) {
    //       return 0.5
    //     }
    //   }
    //   const durationTwo = (e) => {
    //     if (e === 0 || e === 3) {
    //       return 1.5
    //     } else if (e > 0 || e < 3) {
    //       return 2
    //     }
    //   }
    //   const time = this.memberGroup.length * 8000

    //   console.log('delay', delay)
    //   const aboutTl = gsap.timeline({
    //     scrollTrigger: {
    //       // trigger: '.space',
    //       trigger: '.about-member',
    //       start: 'top top',
    //       end: `+=${time}`,
    //       scrub: true,
    //       pin: true
    //     }
    //   })

    //   for (let i = 0; i < this.memberGroup.length; i++) {
    //     const memberTest = gsap.utils.toArray('.member-card')
    //     // const memberBg = gsap.utils.toArray('.member-bg')
    //     if (i === 0) {
    //       aboutTl.to(memberTest, {
    //         x: distanceX,
    //         duration: duration,
    //         ease: 'power1.out',
    //         delay: delay,
    //         onUpdate: function () {
    //           that.index = i
    //         }
    //       })
    //       // tl.to(memberBg, {
    //       //   rotate: distanceX,
    //       //   scale: 0.5,
    //       //   opacity: 0.4,
    //       //   duration: 2,
    //       //   ease: 'none'
    //       // }, '<')
    //     } else if (i > 0 && i < this.memberGroup.length - 1) {
    //       aboutTl.to(memberTest, {
    //         x: distanceY / 2,
    //         duration: durationTwo,
    //         ease: 'power1.out',
    //         delay: delayTwo,
    //         onUpdate: function () {
    //           that.index = i
    //         }
    //       }).to(memberTest, {
    //         x: distanceX,
    //         duration: duration,
    //         ease: 'power1.out',
    //         delay: delay,
    //         onUpdate: function () {
    //           that.index = i
    //         }
    //       })
    //       // tl.to(memberBg, {
    //       //   rotate: -(distanceY / 2),
    //       //   scale: 2,
    //       //   opacity: 1,
    //       //   duration: 2,
    //       //   ease: 'none'
    //       // }, '<')
    //     } else if (i === that.memberGroup.length - 1) {
    //       aboutTl.to(memberTest, {
    //         x: distanceY / 2,
    //         duration: durationTwo,
    //         delay: delayTwo,
    //         ease: 'power1.out',
    //         onUpdate: function () {
    //           that.index = i
    //         }
    //       })
    //     }
    //   }
    // }
  },
  mounted () {
    // this.memberAnimation()
  },
  onUnmounted () {
    // ScrollTrigger.kill()
  }
}
</script>
<style lang="scss" scoped>
%clearfix {
  *zoom: 1;
  &:before, &:after {
    display: table;
    line-height: 0;
    content: "";
  }
  &:after {
    clear: both;
  }
}
.about-intro{
  // background-color: $green;
  background-color: #fff;
  // height: 100vh;
  // position: fixed;
  width: 100%;
  // left: 0;
  // top: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  .header-bg{
    height: 57px;
  }
  .block{
    padding: 30px 40px;
    height: calc(100% - 57px);
    position: relative;
    display: flex;
    flex-direction: column;
    .title{
      width: 100%;
      // display: flex;
      // padding: 0px 0px 10px 0px;
      padding: 0;
      margin-bottom: 20px;
      // align-items: flex-start;
      @extend %clearfix;
      img{
        height: 23px;
        width: 23px;
        margin-right: 8px;
        float: left;
        margin-top: 5px;
      }
      h2{
        color: #000;
        font-size: 20px;
        margin: 0;
        line-height: 30px;
        // margin-top: 3px;
      }
    }
    .intro{
      display: flex;
      margin-bottom: 0px;
      justify-content: center;
      flex-direction: column;
      line-height: 28px;
      .company-intro, .slogan{
        // padding-right: 10%;
        color: #000;
        // letter-spacing: 1px;
        // max-width: 90%;
        margin-bottom: 40px;
        font-size: 16px;
        white-space: pre-line;
      }
    }
    .member{
      margin-bottom: 50px;
      h4{
        font-size: 24px;
        margin: 0;
        margin-bottom: 20px;
      }
      p{
        font-size: 16px;
        letter-spacing: 1px;
        margin-bottom: 40px;
        line-height: 28px;
      }
      .member-block{
        display: flex;
        justify-content: flex-start;
        // justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        flex: 1;
        gap: 20px;
        flex-wrap: wrap;
        .member-card{
          flex-basis: calc(25% - 15px);
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          .memberStyle{
            border: solid 4px #000;
            // background-color: $green;
            padding: 10px;
            overflow: hidden;
            // background-color: $green;
            background-color: #fff;
            margin-bottom: 8px;
            .main{
              color: #fff;
              position: absolute;
              right: 35px;
              top: 35px;
              font-size: 15px;
              letter-spacing: 5px;
            }
            .img{
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              img{
                width: 60%;
              }
            }
          }
          .member{
            position: relative;
            border: solid 3px #000;
          }
          .member-intro{
            color: #000;
            letter-spacing: 2px;
            font-size: 18px;
            display: flex;
            flex-direction: column;
            p{
              margin: 0;
              margin-bottom: 0px;
            }
            .main{
              margin-right: 15px;
            }
          }
        }
      }
      // .member-carousel{
      //   display: none;
      // }
    }
    .relation{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 10px;
      background-color: #000;
      .card{
        flex-basis: calc(25%);
        color: #fff;
        padding: 30px 35px;
        letter-spacing: 1px;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        h6{
          margin: 0;
          border-bottom: solid 2px #fff;
          padding-bottom: 20px;
          margin-bottom: 20px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
@media (min-width: 1500px) {
  .about-intro{
    .block{
      // .title{
      //   img{
      //     height: 40px;
      //     margin-right: 10px;
      //   }
      //   h2{
      //     font-size: 24px;
      //   }
      // }
      .intro{
        .left, .right{
          .title{
            font-size: 22px;
            margin-bottom: 15px;
            letter-spacing: 1px;
          }
          p{
            letter-spacing: 1px;
            font-size: 18px;
            line-height: 35px;
          }
        }
      }
      .member-block{
        .member-card{
          .memberStyle{
            .main{
              right: 40px;
              top: 40px;
              font-size: 20px;
              letter-spacing: 10px;
            }
            .name{
              left: 50px;
              bottom: 40px;
              font-size: 20px;
              letter-spacing: 2px;
            }
          }
          p.member-intro{
            letter-spacing: 2px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .about-intro{
    .block{
      padding: 30px 5%;
      .intro{
        .company-intro, .slogan{
          max-width: 100%;
          padding-right: 0px;
        }
      }
      .member{
        .member-block{
          display: none;
        }
        // .member-carousel{
        //   display: block;
        // }
      }
      .relation{
        .card{
          flex-basis: 33.33%;
          padding: 25px 20px;
        }
      }
    }
  }
}
@media (max-width: 840px) {
  .about-intro{
    .block{
      .relation{
        flex-direction: column;
        .card:last-child{
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
