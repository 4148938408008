import { render } from "./HomeService.vue?vue&type=template&id=27aae610&scoped=true&lang=pug"
import script from "./HomeService.vue?vue&type=script&lang=js"
export * from "./HomeService.vue?vue&type=script&lang=js"

import "./HomeService.vue?vue&type=style&index=0&id=27aae610&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-27aae610"

export default script
import QResponsive from 'quasar/src/components/responsive/QResponsive.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QResponsive});
