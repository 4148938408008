<template lang="pug">
.footer
  .left
    //- .brand
    //-   img.logo-icon(src="@/assets/logo-white.svg")
    //-   img(src="@/assets/name-white.svg")
    .copyright © 2022 by Woosha Design.
  .wrap
    .center
      .data
        div
          a(:href="`mailto:${getFooterData.contactInfo?.[1]?.value}`" target="_blank" :aria-label="`mailto:${getFooterData.contactInfo?.[1]?.value}`") {{ getFooterData.contactInfo?.[1]?.value }}
        div
          a(:href="`http://maps.google.com.tw/maps?q=${getAddressInfo?.[0]?.value}`" target="_blank" :aria-label="`address:${getAddressInfo?.[0]?.value}`") 
            div {{getAddressInfo?.[0]?.name}}
            div {{ getAddressInfo?.[0]?.value }}
          a(:href="`tel:${getTelInfo?.[0]}`" target="_blank" :aria-label="`Tel:${getTelInfo?.[0]}`") Tel:{{ getTelInfo?.[0] }}
        div
          a(:href="`http://maps.google.com.tw/maps?q=${getAddressInfo?.[1]?.value}`" target="_blank" :aria-label="`address:${getAddressInfo?.[0]?.value}`") 
            div {{getAddressInfo?.[1]?.name}}
            div {{ getAddressInfo?.[1]?.value }}
          a(:href="`tel:${getTelInfo?.[1]}`" target="_blank" :aria-label="`Tel:${getTelInfo?.[1]}`") Tel:{{ getTelInfo?.[1] }}

    .right
      a.fb(:href="getFooterData.socialMedia.facebook" target="_blank" aria-label="fb")
        img(src="@/assets/fb.svg" alt="fb_icon")
      a.ig(:href="getFooterData.socialMedia.ig" target="_blank" aria-label="ig")
        img(src="@/assets/ig.svg" alt="ig_icon")
      a.line(:href="getFooterData.socialMedia.line" target="_blank" aria-label="line")
        img(src="@/assets/line.svg" alt="line_icon")
      a.phone(:href="`tel:${getFooterData.socialMedia.phone}`" target="_blank" aria-label="phone")
        img(src="@/assets/phone.svg" alt="phone_icon")
</template>

<script>
export default {
  name: 'Footer',
  // props: {
  // },
  // data () {
  //   const contactList = {
  //     tel: 'Tel: +886-4-23716688',
  //     mail: 'info@woosha-design.com',
  //     address: '40344 台中市西區大全街74號5樓'
  //   }
  //   return {
  //     contactList
  //   }
  // },
  computed: {
    getFooterData () {
      console.log(this.$store.state.webContent.footer)
      return this.$store.state.webContent.footer
    },

    getTelInfo () {
      return this.$store.state.webContent.footer.contactInfo[0]?.value.split('/')
    },

    getAddressInfo () {
      const addressArr = this.$store.state.webContent.footer.contactInfo[2]?.value.split('/')
      return addressArr?.map(item=>{
        const data = item.split(':')
        return {
          name: data[0],
          value: data[1]
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.footer{
  width: 100%;
  background-color: #000;
  padding: 30px 40px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  .left{
    width: 180px;
    // .brand{
    //   display: inline-flex;
    //   margin-bottom: 10px;
    //   .logo-icon{
    //     margin-right: 15px;
    //   }
    // }
  }
  .copyright{
    font-size: 13px;
    white-space: nowrap;
  }
  .wrap{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 15px;
    .center{
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
      // border: solid 1px red;
      div{
        margin: 0;
      }
      .data{
        // display: flex;
        // align-items: center;
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        &>div{
          display: flex;
          margin: 0px 5px;
        }
        div{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;

        }
      }
      a{
        color: #fff;
        text-decoration: none;
        font-size: 13px;
        display: flex;
        gap: 10px;
        text-align: center;
      }
    }
    .right{
      display: flex;
      align-items: center;
      a{
        display: flex;
        justify-content: center;
        width: 26px;
        height: 26px;
        margin: 0px 10px;
      }
    }
  }
}

// @media(max-width: 1240px) {
// .footer{
//   .wrap{
//     flex-direction: column;
//     align-items: flex-end;
//   }
//   .left{
//     border: solid 1px red;
//     display: flex;
//     height: 100px;
//   }
//   .center{
//     flex-wrap: wrap;
//     margin-bottom: 20px;
//     div{
//       margin: 0px 15px;
//     }
//     a:last-child{
//       margin-right: 0px;
//     }
//   }
//   .right{
//     div{
//       width: 20px;
//       height: 20px;
//     }
//   }
// }
// }

@media(max-width: 1500px) {
.footer{
  flex-direction: column;
  align-items: center;
  .left{
    border-bottom: solid 2px #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    .copyright{
      display: none;
    }
  }
  .wrap{
    padding: 20px 0px;
    align-items: center;
    flex-direction: column;
    .center{
      margin: 10px 0px 30px 0px;
      div{
        flex-direction: column;
        margin: 0;
        // margin-bottom: 15px;
        letter-spacing: 1px;
      }
      a{
        margin: 0px;
      }

    }
  }
}
}
@media(max-width: 1024px) {
  .footer{
    padding: 30px 5%;
  }
}
@media(max-width: 1200px) {
  .footer{
    .wrap{
      .center{
        .data{
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
      }
    }
  }
}
@media(max-width: 745px) {
  .footer{
    .wrap{
      .center{
        margin: 10px 0px 20px 0px;
        .data{
          width: 100%;
          display: flex;
          width: 100%;
          justify-content: center;
          margin: 0px 0px 15px 0px;
          a{
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
