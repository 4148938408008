<template lang="pug">
.post
  div
    template(v-if="isNotFound === false")
      .header-bg
      .prev-post(v-if="getOtherPost.prev")
        router-link(:to="`/post/${encodeURIComponent(getOtherPost.prev.title)}`" aria-label="prev-article")
          .arrow
            img(src="@/assets/post-arrow.svg" alt="arrow")
          .text 上一則
          .post-box
            img(:src="defaultTo(getOtherPost.prev.imgs.filter(e=>e.visible===true)?.[0]?.url , '/images/no-image.svg')" alt="prev")
      .next-post(v-if="getOtherPost.next")
        router-link(:to="`/post/${encodeURIComponent(getOtherPost.next.title)}`" aria-label="next-article")
          .arrow
            img(src="@/assets/post-arrow.svg" alt="arrow")
          .text 下一則
          .post-box
            img(:src="defaultTo( getOtherPost.next.imgs.filter(e=>e.visible===true)?.[0]?.url , '/images/no-image.svg')" alt="next")
      .block
        .max-width
          .top
            .left(:style="titleWidth")
              .title
                div.pre-title
                  img(src="@/assets/move.svg" alt="woosha_logo") 
                  div Post
                h2(ref="title") {{ getPost[0].title}}
            .right(ref="tagBox")
              .publish Publish at
                span.date {{ getPost[0].dateTime}}
              .tags
                .tag(v-for="tag in getPost[0].style")
                  router-link(:to="`/blog?tag=${tag.en}`" aria-label="article_tag") {{ tag.tw }}
                .tag
                  router-link(:to="`/blog?tag=all`" aria-label="all_article") 所有文章
          //- q-responsive(:ratio="width> 980 ? 5/2 : 2/1" style="width: 100%" class="main-slider")
          //-   q-carousel(
          //-     v-model="slide"
          //-     transition-prev="slide-right"
          //-     transition-next="slide-left"
          //-     swipeable
          //-     animated
          //-     infinite
          //-     :autoplay="8000"
          //-     control-color="white"
          //-     navigation
          //-     arrows
          //-   )
          //-     q-carousel-slide(:name="index" class="column no-wrap flex-center" v-for="(item, index) in getPost[0].imgs.filter(e=>e.visible===true)" :key="index")
          //-       img(:src="item?.url ? '/images/no-image.svg' : item?.url")
          //- iframe(:src="`data:text/html;charset=utf-8,${encodeURIComponent(getPost[0].content)}`" frameborder="0" scrolling="no")
          .post-content(v-html="getPost[0].content" ref="vHtmlContent" style="min-height: 100vh")
      .mobile-arrow
        .prev-post(v-if="getOtherPost.prev")
          router-link(:to="`/post/${encodeURIComponent(getOtherPost.prev.title)}`" aria-label="prev-article")
            .arrow
              img(src="@/assets/post-arrow.svg" alt="arrow")
              .text 上一則
            .name {{ getOtherPost.prev.title }}
            q-responsive(:ratio="5/3")
              img(:src="defaultTo( getOtherPost.prev.imgs.filter(e=>e.visible===true)?.[0]?.url , '/images/no-image.svg')" alt="prev")
        .next-post(v-if="getOtherPost.next")
          router-link(:to="`/post/${encodeURIComponent(getOtherPost.next.title)}`" aria-label="next-article")
            .arrow
              img(src="@/assets/post-arrow.svg" alt="arrow")
              .text 下一則
            .name {{ getOtherPost.next.title }}
            q-responsive(:ratio="5/3")
              img(:src="defaultTo(getOtherPost.next.imgs.filter(e=>e.visible===true)?.[0]?.url , '/images/no-image.svg')" alt="next")
      .more(v-if="getMorePosts && getMorePosts.length!==0")
        .max-width
          .main-title 更多推薦
          swiper(
            class="swiper-container"
            :navigation={
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }
            :pagination="{ draggable: true }"
            :autoplay="{ disableOnInteraction: false }"
            :slides-per-view="width > 690 ? 4 : 2"
            :space-between="20"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          )
            swiper-slide(v-for="(item, index) in getMorePosts")
              router-link(:to="`/post/${encodeURIComponent(item.title)}`" aria-label="article")
                q-responsive(:ratio="16/12")
                  img(:src="defaultTo(item.imgs.filter(e=>e.visible===true)?.[0]?.url, '/images/no-image.svg')" alt="article_image")
                .title {{ item.title }}
                .date {{ item.dateTime }}
      .btn-area
        .max-width
          .home-btn
            router-link(to="/" aria-label="home") 回首頁
    template(v-else-if="isNotFound === true")
      page404
    //- template(v-if="isNotFound === null && loadEnd === false")
    //-   PageLoading
  PageLoading(v-if="loadEnd !== true && notFound === null")

</template>

<script>
import { useRoute } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from 'quasar';
import {defaultTo} from '../common/utils'
import { ref, computed, watch, nextTick } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
import page404 from '../components/404.vue';
import { getArticles } from '@/api/api'
import PageLoading from '../components/PageLoading.vue';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay
} from 'swiper'
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay
])

export default {
  name: 'Post',
  components: {
    Swiper,
    SwiperSlide,
    page404,
    PageLoading
  },
  data () {
    return {
      notFound: false,
      tagWidth: 0,
      tag: null,
      width: 0,
      slide: ref(0),
      lorem: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo provident incidunt ducimus iusto perferendis porro earum. Totam, numquam?',
      postDefault: {
        _id: '',
        title: '',
        dateTime: '',
        style: [
          {
            tw: '',
            en: ''
          }
        ],
        content: '',
        imgs: [
          {
            url: '',
            visible: true
          }
        ]
      },
      selfGetArticles: {},
      isAsyncEnd: false,
      loadEnd: false,
      notFound: null
    }
  },
  methods: {
    getWindowWidth () {
      this.width = window.innerWidth
    },
    defaultTo(datum, defaultValue){
      return defaultTo(datum, defaultValue)
    },
    getImgLoadEnd() {
      const that = this
      that.loadEnd = false
      let images = this.$refs?.vHtmlContent?.getElementsByTagName('img')
      if(!images) return
      if(images.length !== 0) {
        const promises = Array.prototype.slice.call(images).map(node => {
          return new Promise((resolve, reject) => {
            let loadImg = new Image();
            loadImg.src = node.src;
            loadImg.onload = ()=>{
              resolve(node)
            }
          })
        })
        Promise.all(promises).then(results=>{
          that.loadEnd = true
        }).catch(e => {
          console.error(e)
        })
      }else{
        that.loadEnd = true
      }
    }
    // resizeIframe() {
    //   console.log('obj', this.$refs.iframeEl);
    //   const obj = this.$refs.iframeEl
    //   obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
    // }
  },
  mounted () {
    this.$store.commit('LOADINGANIMATION', true)
    this.getWindowWidth()
    
    window.addEventListener('resize', this.getWindowWidth)
    console.log('vue2', this.handleMeta)
    
  },
  onUnmounted () {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  computed: {
    getPost () {
      if (this.$store.state.articles.length > 0) {
        const result = this.$store.state.articles.filter(e => e.title === decodeURIComponent(this.$route.params.postId))
        return result
      } else {
        return [this.postDefault]
      }
    },
    getOtherPost () {
      if (this.$store.state.articles.length > 0) {
        const targetIndex = this.$store.state.articles.findIndex(e => e.title === decodeURIComponent(this.$route.params.postId))
        let result = {}
        if (targetIndex !== -1 && this.$store.state.articles.length > 2) {
          if (targetIndex === 0) {
            result = {
              prev: this.$store.state.articles[this.$store.state.articles.length - 1],
              next: this.$store.state.articles[targetIndex + 1]
            }
          } else if (targetIndex === this.$store.state.articles.length - 1) {
            result = {
              prev: this.$store.state.articles[this.$store.state.articles.length - 2],
              next: this.$store.state.articles[0]
            }
          } else {
            result = {
              prev: this.$store.state.articles[targetIndex - 1],
              next: this.$store.state.articles[targetIndex + 1]
            }
          }
        } else if (targetIndex !== -1 && this.$store.state.articles.length > 1) {
          if (targetIndex === 0) {
            result = {
              prev: this.$store.state.articles[this.$store.state.articles.length - 1],
              next: this.$store.state.articles[this.$store.state.articles.length - 1]
            }
          } else if (targetIndex === this.$store.state.articles.length - 1) {
            result = {
              prev: this.$store.state.articles[0],
              next: this.$store.state.articles[0]
            }
          }
        }
        return result
      } else {
        const result = {
          prev: this.postDefault,
          next: this.postDefault
        }
        return result
      }
    },
    getMorePosts () {
      if (this.$store.state.articles.length > 1) {

        return this.$store.state.articles.filter(e => {
          let isExist = false
          e.style.forEach(e=>{
            if(this.getPost[0].style.find(j=>j.tw === e.tw)){
              isExist = true
            }
          })
          return (e.title !== decodeURIComponent(this.$route.params.postId)) && isExist
        })
      } else {
        return false
      }
    },
    titleWidth () {
      return `min-width: calc(100% - ${this.tagWidth + 20}px)`
    },
    isNotFound () {
      if(!this.$store.state.isAsyncEnd) {
        return null
      }
      if(this.getPost.length !==0) {
        return false
      }else{
        return true
      }
    }
  },
  watch: {
    getPost: {
      async handler() {
        await this.$nextTick()
        /** @type {HTMLDivElement} */
        const tagBox = this.$refs.tagBox
        if(tagBox) {
          this.tagWidth = tagBox.clientWidth
        }
        if(this.getPost && this.getPost[0] && this.getPost[0].title) {
          this.handleMeta(this.getPost[0].title, this.getPost[0].title)
          this.isAsyncEnd = true
          this.$nextTick(()=>{
            this.getImgLoadEnd()
          })
          console.log('vuex', 'isAsyncEnd', this.isAsyncEnd)
        }else{
          const that = this
          getArticles().then((result) => {
            that.selfGetArticles = result.data.sort((a, b) => {
              return a.dateTime < b.dateTime ? 1 : -1
            })
            that.isAsyncEnd = true
            console.log('self', 'isAsyncEnd', this.isAsyncEnd)
          }).catch((err) => {
            console.log(err)
          })
        }
      },
      deep: true,
      immediate: true
    },
    isNotFound: {
      handler() {
        if(this.isNotFound === true){
          if(!!document.head.querySelector("meta[name=prerender-status-code]")) return;
          const meta = document.createElement('meta');
          meta.name = "prerender-status-code"
          meta.content ="404"
          document.head.appendChild(meta);
          this.notFound = true
        }else if(this.isNotFound === false){
          this.handleMeta(this.getPost[0].name, this.getPost[0].name)
        }
      }
    },

    
    // isAsyncEnd: {
    //   handler() {
    //     if(this.isAsyncEnd === true){
    //       // this.handleMeta(this.getPost[0].title, '')
    //       if(this.getPost && this.getPost[0] && this.getPost[0].title) {
    //         this.handleMeta(this.getPost[0].title, '')
    //       }else{
    //         this.notFound = true
    //         if(!!document.head.querySelector("meta[name=prerender-status-code]")) return;
    //         const meta = document.createElement('meta');
    //         meta.name = "prerender-status-code"
    //         meta.content ="404"
    //         document.head.appendChild(meta);
    //       }
    //     }        
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  updated() {
    if(this.$refs.tagBox) {
      this.tagWidth = this.$refs.tagBox.clientWidth
    }
  },
  setup(){
    const handleMeta = (title, description)=>{
      useMeta(() => ({
        title: `${title} | Woosha Design 舞夏設計`,
        meta: {
          description: { name: 'description', content: description}
        }
      }))
    }
    
    return {handleMeta}
  }
}
</script>

<style lang="scss" scoped>
.test{
  width: 100vw;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post{
  min-height: 100vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  :deep() iframe{
    border: 0;
  }
  .header-bg{
    height: 57px;
  }
  // :deep() hr{
  //   color: red !important;
  // }
  .mobile-arrow{
    display: none;
  }
  .prev-post, .next-post{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    a{
      color: #000;
      text-decoration: none;
    }
    .text{
      text-align: center;
      margin-bottom: 5px;
      font-weight: 900;
    }
    .arrow{
      width: 100px;
      margin-bottom: 0px;
      img{
        width: 100%;
      }
    }
    .post-box{
      border: solid 4px #000;
      position: relative;
      width: 100px;
      height: 70px;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
      }
    }
  }
  .prev-post{
    left: 40px;
  }
  .next-post{
    right: 40px;
    .arrow{
      transform: rotateY(180deg);
    }
  }
  // .next-post{
  //   position: fixed;
  // }
  .block{
    padding: 30px 180px;
    position: relative;
    display: flex !important;
    align-items: center;
    flex-direction: column;
    // background-color: $green;
    background-color: #fff;
    .max-width{
      max-width: 980px;
    }
    .main-slider{
      margin-bottom: 30px;
    }
    .q-carousel{
      .q-carousel__slide{
        background-color: #000;
      }
    }
    .top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 5px;
      .left{
        margin-bottom: 20px;
        margin-right: 15px;
        .title{
          width: 100%;
          display: flex;
          padding: 0px;
          align-items: flex-start;
          .pre-title{
            display: flex;
            align-items: center;
            img{
              height: 28px;
              margin-right: 5px;
            }
            div{
              font-size: 20px;
              line-height: 23px;
              padding-right: 10px;
            }
          }
          h2{
            border-left: solid 2px #000;
            padding-left: 10px;
            color: #000;
            font-size: 20px;
            margin: 0;
            line-height: 28px;
          }
        }
      }
      .right{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;
        // margin-bottom: 20px;
        .publish{
          margin-right: 15px;
          margin-bottom: 10px;
          .date{
            margin-left: 10px;
            white-space: nowrap;
          }
        }
        .tags{
          display: flex;
          gap: 10px;
          a{
            color: #000;
            text-decoration: none;
            border: solid 1px #000;
            padding: 0px 10px;
            white-space: nowrap;
          }
        }
      }
    }
    .post-content{
      padding-bottom: 30px;
      border-bottom: dashed 2px #000;
      font-size: 16px;
      :deep() * {
        letter-spacing: 2px;
      }
      
      :deep() a {
        word-break: break-all;
      }
      // :deep() hr {
      //   border: solid 1px #000;
      // }
      :deep() img {
        max-width: 100%;
        object-fit: contain;
      }
      :deep() iframe{
        max-width: 100% !important
      }
        
      // :deep() table{
      //   tr{
      //     td{
      //       height: 100% !important;
      //       overflow: hidden;
      //       img{
      //         min-height: 100%;
      //         min-width: 100%;
      //         object-fit: cover;
      //       }
      //     }
      //   }
      // }
    }
  }
  .more{
    padding: 10px 180px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    .max-width{
      max-width: 980px;
      width: 100%;
    }
    .swiper-container{
      padding-bottom: 50px;
      a{
        color: #000;
        text-decoration: none;
        .title{
          font-weight: 500;
          font-size: 16px;
          margin: 10px 0px 8px 0px;
        }
        .q-responsive{
          img{
            object-fit: cover;
          }
        }
      }
    }
    :deep() .swiper-pagination{
      .swiper-pagination-bullet-active{
        background-color: #000;
      }
    }
    .main-title{
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .more-block{
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .more-post{
        width: calc(25% - 15px);
        a{
          color: #000;
          text-decoration: none;
        }
        .q-responsive{
          width: 100%
        }
      }
    }
  }
  .btn-area{
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 180px 50px 180px;
    .max-width{
      max-width: 980px;
      width: 100%;
    }
    .home-btn{
      background-color: #000;
      // margin: 0px 180px 50px 180px;
      padding: 15px 50px;
      text-align: center;
      border-radius: 5px;
      width: 100%;
      a{
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        letter-spacing: 3px;
        transition-duration: .5s;
        &:hover{
          letter-spacing: 10px;
        }
      }
    }
  }
}
// @media (max-width: 1024px) {
//   .post{
//     .block{
//       padding: 30px 5%;
//       padding-bottom: 0px;
//       .top{
//         margin-bottom: 10px;
//       }
//       .q-responsive{
//         display: none;
//       }
//     }
//   }
// }
@media (max-width: 1024px) {
  .post{
    .prev-post, .next-post{
      display: none;
    }
    .block{
      // padding: 30px;
      // padding-bottom: 0px;
      padding: 30px 5%;
      padding-bottom: 0px;
      .top{
        margin-bottom: 10px;
      }
      .post-content{
        border-bottom: solid 2px #000;
      }
      .q-responsive{
        display: none;
      }
    }
    .mobile-arrow{
      display: flex;
      justify-content: space-between;
      padding: 0px 5%;
      gap: 0px;
      padding-bottom: 30px;
      align-items: stretch;
      background-color: #fff;
      .prev-post, .next-post{
        display: flex;
        position: relative;
        transform: translate(0);
        flex: 1;
        border-bottom: solid 2px #000;
        padding: 0px 5px;
        justify-content: flex-start;
        a{
          display: flex;
          // flex-direction: column;
          align-items: center;
          flex-direction: column;
          .arrow{
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0px;
            width: 100%;
            img{
              width: 30px;
            }
          }
          .q-responsive{
            // width: 100%;
            // max-width: 110px;
            flex: 1;
            border: solid 4px #000;
            display: none;
            img{
              min-width: 100%;
              min-height: 100%;
              object-fit: cover;
            }
          }
          .text{
            margin: 10px 15px;
            white-space: nowrap;
          }
          .name{
            margin: 0px 10px 10px;
          }
        }
      }
      .prev-post{
        left: 0;
        border-right: solid 1px #000;
        // a{
        //   align-items: flex-start;
        // }
      }
      .next-post{
        right: 0;
        border-left: solid 1px #000;
        .text{
          transform: rotateY(180deg);
        }
      }
    }
    .more{
      padding: 0px 5%;
    }
    .btn-area{
      padding: 0px 5% 30px 5%;
      .home-btn{
        // margin: 15px 30px 50px 30px;
      }
    }
  }
}

@media (max-width: 690px){
  .post{
    .mobile-arrow{
      .prev-post, .next-post{
        a{
          flex-direction: column;
          .arrow{
            img{
              max-width: 100px;
            }
          }
        }
      }
      // .prev-post{
      //   a{
      //     align-items: flex-start;
      //   }
      // }
      // .next-post{
      //   a{
      //     align-items: flex-end;
      //   }
      // }
    }
  }
}
@media (max-width: 540px){
  .post{
    .block{
      .top{
        .left{
          .title{
            flex-direction: column;
            .pre-title{
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
