<template lang="pug">
.member-slide
  q-carousel.member-carousel(v-model="projectSlide" swipeable animated arrows infinite transition-prev="slide-right" transition-next="slide-left")
    q-carousel-slide(:name="i" class="column no-wrap flex-center" v-for="(data, i) in parentMsg" key="i")
      img(:src="data.imgUrl" alt="member")
  .member-intro
    p.main {{ parentMsg[projectSlide].jobTitle }}
    p.name {{ parentMsg[projectSlide].name }}
</template>

<script>

export default {
  name: 'Carousel',
  components: {
  },
  props: [
    'parentMsg'
  ],
  data () {
    return {
      autoplay: true,
      slide: 0,
      projectSlide: 0
    }
  },
  methods: {
    init () {
      console.log(this.parentMsg)
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss">
.member-slide{
  display: none;
}
@media (max-width: 1024px) {
  .member-slide{
    display: flex;
    flex-direction: column;
    .q-carousel{
      background-color: rgba(0, 0, 0, 0);
    }
    .member-carousel{
      height: 65vw;
      border: solid 3px #000;
      .q-carousel__control{
        .q-btn{
          .q-icon{
            color: #000;
            font-weight: 900;
          }
        }
      }
      .q-carousel__slide{
        padding: 20px;
        // background-color: #4e6554;
        background-color: #fff;
      }
      img{
        height: 80%;
        pointer-events: none;
      }
    }
    .member-intro{
      margin-top: 10px;
      .main{
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      .name{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
