<template lang="pug">
.project
  template(v-if="isNotFound === false")
    .header-bg
    .block
      .project-top(v-if="getProject[0]" ref="allEl")
        //- div {{ titleWidth }} | {{ allWidth }} | {{ titleWrap }}
        .left
          img(src="@/assets/move.svg" alt="woosha_logo")
          //- h2.project-name Our Project | 專案案例
          h2.project-name(ref="titleEl" :style="titleWrap") {{ getProject[0].name === '' ? getProject[0].enName : getProject[0].name}}
        .right(ref="tagBox")
          router-link.tag(v-for="tag in getProject[0].style" :to="`/works?tag=${tag.en}`" aria-label="project_tag") {{tag.tw}}
      .project-block(v-if="getProject[0]")
        .inner-left
          .mobile-only(v-if="windowWidth<=830")
            .type-block
              p Type | {{ getProject[0].style[0].tw }}
            .area-block
              p Area | {{ getProject[0].county }}
            .mobile-img-block
              template(v-for="imgData in getProject[0].imgs.filter(e=>e.visible===true)")
                img(:src="imgData.url" alt="project_image")
          //- .title-block
          //-   p Name | {{ getProject[0].name === '' ? getProject[0].enName : getProject[0].name}}
          .border-block
            .border-top
            q-scroll-area.img-block(:visible="true" :thumb-style="thumbStyle" :content-style="contentStyle" :content-active-style="contentActiveStyle")
              template(v-for="imgData in getProject[0].imgs.filter(e=>e.visible===true)")
                img(:src="imgData.url" alt="project_image")
            .border-bottom
          q-responsive(:ratio="32/23" v-if="windowWidth>830")
            .border-top
            q-scroll-area.img-block(:visible="true" :thumb-style="thumbStyleMobile" :content-style="contentStyle" :content-active-style="contentActiveStyle")
              template(v-for="imgData in getProject[0].imgs.filter(e=>e.visible===true)")
                img(:src="imgData.url" alt="project_image")
            .border-bottom
        .inner-right
          .type-block(v-if="windowWidth>830")
            p Type | {{ getProject[0].style[0].tw }}
          .area-block(v-if="windowWidth>830")
            p Area | {{ getProject[0].county }}
          .intro-block
            .mobile
              p(v-html="getProject[0].description" v-if="getProject[0].description !== ''")
              p(v-else) {{ getProject[0].name === '' ? getProject[0].enName : getProject[0].name }}
            q-scroll-area.scroll-bar(:thumb-style="thumbStyle")
              p(v-html="getProject[0].description" v-if="getProject[0].description !== ''")
              p(v-else) {{ getProject[0].name === '' ? getProject[0].enName : getProject[0].name }}
          .scroll-bottom
  template(v-else-if="isNotFound === true")
    page404
  template(v-else-if="isNotFound === null")
    PageLoading
</template>

<script>
import { defaultTo } from 'lodash'
import {ref, watch, computed} from 'vue'
import { useMeta } from 'quasar';
import page404 from '../components/404.vue';
import PageLoading from '../components/PageLoading.vue';
import { getProjects } from '@/api/api';

export default {
  name: 'Project',
  components: {
    page404,
    PageLoading
  },
  props: [],
  data () {
    return {
      windowWidth: null,
      // refreshKey: 0,
      notFound: false,
      tagWidth: 0,
      allWidth: 0,
      titleWidth: 0,
      contentStyle: {
        backgroundColor: 'rgba(0,0,0,0.02)',
        color: '#555'
      },

      contentActiveStyle: {
        backgroundColor: '#eee',
        color: 'black'
      },
      thumbStyle: {
        right: '3px',
        borderRadius: '5px',
        backgroundColor: '#FF9540',
        width: '5px',
        opacity: 0.75
      },
      thumbStyleMobile: {
        right: '2px',
        borderRadius: '5px',
        backgroundColor: '#FF9540',
        width: '5px',
        opacity: 0.75
      },
      isAsyncEnd: false,
      selfGetProjects: {},
    }
  },
  computed: {
    getProject () {
      return this.$store.state.projects.filter(e => e.name === this.$route.params.projectId || e.enName === this.$route.params.projectId)
    },
    isNotFound () {
      if(!this.$store.state.isAsyncEnd) return null
      if(this.getProject.length !==0) {
        return false
      }else{
        return true
      }
    },
    // titleWidth () {
    //   return `min-width: ${this.allWidth - this.tagWidth - 20 + 35}px;`
    // },
    getProjectAndTagBox() {
      return {p: this.getProject, r: this.$refs};
    },
    titleWrap(){
      if(this.allWidth < this.titleWidth+31){
        return 'white-space: pre-wrap;'
      }else{
        return 'white-space: nowrap; display: flex;'
      }
    }
  },
  watch: {
    getProject: {
      async handler(val) {
        await this.$nextTick()
        /** @type {HTMLDivElement} */

        const tagBox = this.$refs.tagBox
        const allEl = this.$refs.allEl
        const titleEl = this.$refs.titleEl
        if(!tagBox || !allEl || !titleEl) {
        }else{
          this.tagWidth = tagBox.clientWidth
          this.allWidth = allEl.clientWidth
          this.titleWidth = titleEl.clientWidth
        }

        if(this.getProject && this.getProject[0] && this.getProject[0].name) {
          this.handleMeta(this.getProject[0].name, this.getProject[0].name)
          this.isAsyncEnd = true
          console.log('vuex', 'isAsyncEnd', this.isAsyncEnd)
        }else{
          const that = this
          getProjects().then((result) => {
            that.selfGetProjects = result.data.filter(e => e.name === this.$route.params.projectId || e.enName === this.$route.params.projectId)
            that.isAsyncEnd = true
            console.log('self', 'isAsyncEnd', this.isAsyncEnd)
          }).catch((err) => {
            console.log(err)
          })
        }
      },
      deep: true,
      immediate: true
    },
    isNotFound: {
      handler() {
        if(this.isNotFound === true){
          if(!!document.head.querySelector("meta[name=prerender-status-code]")) return;
          const meta = document.createElement('meta');
          meta.name = "prerender-status-code"
          meta.content ="404"
          document.head.appendChild(meta);
        }else if(this.isNotFound === false){
          this.handleMeta(this.getProject[0].name, this.getProject[0].name)
        }
      }
    },
    // isAsyncEnd: {
    //   handler() {
    //     if(this.isAsyncEnd === true){
    //       if(this.getProject && this.getProject[0] && this.getProject) {
    //         this.handleMeta(this.getProject[0].name, '')
    //         this.notFound = false
    //       }else{
    //         this.notFound = true
    //         if(!!document.head.querySelector("meta[name=prerender-status-code]")) return;
    //         const meta = document.createElement('meta');
    //         meta.name = "prerender-status-code"
    //         meta.content ="404"
    //         document.head.appendChild(meta);
    //       }
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // },
    windowWidth: {
      handler() {
        this.refreshKey++
      },
      deep: true,
      immediate: true
    }
  },
  updated() {
    if(this.$refs.tagBox) {
      this.tagWidth = this.$refs.tagBox.clientWidth
    }
    if(this.$refs.allEl) {
      this.allWidth = this.$refs.allEl.clientWidth
    }
    if(this.$refs.titleEl) {
      this.titleWidth = this.$refs.titleEl.clientWidth
    }
  },
  mounted () {
    this.$store.commit('LOADINGANIMATION', true)
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', ()=>{
      this.windowWidth = window.innerWidth
      console.log(this.windowWidth)
    })

    // this.tagWidth = this.$refs.tagBox
  },
  setup(){
    const handleMeta = (name, description)=>{
      useMeta(() => ({
        title: `${name} | Woosha Design 舞夏設計`,
        meta: {
          description: { name: 'description', content: description}
        }
      }))
    }
    
    return {handleMeta}
  }
}
</script>

<style lang="scss" scoped>
%clearfix {
	*zoom: 1;
	&:before, &:after {
		display: table;
		line-height: 0;
		content: "";
	}
	&:after {
		clear: both;
	}
}
.project{
  width: 100%;
  display: flex;
  flex-direction: column;
  // border: solid 1px red;
  .header-bg{
    height: 57px;
  }
  .block{
    padding: 0px 40px;
    position: relative;
    display: flex !important;
    flex-direction: column;
    // background-color: $green;
    background-color: #fff;
    height: calc(100vh - 56px);
    .project-top{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0px 10px 0px;
      background-color: #fff;
      gap: 15px 10px;
      z-index: 999;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        top: -1px;
        width: 100%;
        height: 1px;
        background-color: $green;
      }
      .left{
        margin-bottom: 0px;
        // display: flex;
        // align-items: center;
        @extend %clearfix;
        img{
          height: 23px;
          width: 23px;
          margin-right: 8px;
          float: left;
          margin-top: 5px;
        }
        h2{
          color: #000;
          font-size: 20px;
          margin: 0;
          line-height: 30px;
          // margin-right: 31px;
          // width: 100%;
        }
      }
      .right{
        display: flex;
        gap: 10px;
        // margin-bottom: 15px;
        a{
          text-decoration: none;
          // color: #fff;
          color: #000;
          font-size: 16px;
          white-space: nowrap;
        }
      }
    }
    .project-block{
      display: flex;
      justify-content: center;
      align-items: stretch;
      // height: calc(100vh - 300px);
      height: 100%;
      margin-bottom: 30px;
      margin-top: 10px;
      // background-color: $green;
      background-color: #fff;
      .inner-left{
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: .7;
        margin-right: 2%;
        position: relative;
        .mobile-only{
          display: block !important;
          .type-block{
            border-top: solid 1px #000;
            padding: 7px 0px;
            p{
              margin-bottom: 0px;
              font-size: 16px;
            }
          }
          .area-block{
            border-top: solid 1px #000;
            padding: 7px 0px;
            p{
              font-size: 16px;
              margin-bottom: 0px;
            }
          }
          .mobile-img-block{
            background-color: #000;
            padding: 12px 12px 0px 12px;
            margin-bottom: 0px;
            position: relative;
            // height: calc(100% - 60px);
            height: calc(100% - 25px);
            overflow: hidden;
            img{
              width: 100%;
              margin-bottom: 10px;
            }
          }
        }
        // .title-block{
        //   color: #000;
        //   display: flex;
        //   align-items: center;
        //   background-color: $green;
        //   position: relative;
        //   &::before{
        //     content: '';
        //     width: calc(100% + 6px);
        //     height: 100%;
        //     top: -3px;
        //     background-color: $green;
        //     position: absolute;
        //     z-index: 98;
        //   }
        //   p{
        //     border-top: solid 2px #000;
        //     border-bottom: solid 2px #000;
        //     padding: 10px 0px;
        //     margin: 0;
        //     font-size: 16px;
        //     width: 100%;
        //     z-index: 99;
        //   }
        // }
        .q-responsive{
          display: none;
        }
        .border-block{
          // height: calc(100% - 48px);
          height: 100%;
          :deep() .q-responsive__content{
            display: flex;
            flex-direction: column;
          }
        }
        .border-top{
          height: 12px !important;
          background-color: #000;
        }
        .border-bottom{
          height: 12px !important;
          background-color: #000;
        }
        .img-block{
          background-color: #000;
          padding: 0px 12px 0px 12px;
          margin-bottom: 0px;
          position: relative;
          // height: calc(100% - 60px);
          height: calc(100% - 25px);
          overflow: hidden;
          img{
            width: 100%;
            margin-bottom: 10px;
          }
          img:last-child{
            margin-bottom: 0px;
          }
        }
      }
      .inner-right{
        flex: .3;
        color: #000;
        display: flex;
        flex-direction: column;
        // height: 100%;
        justify-content: stretch;
        .type-block{
          border-top: solid 2px #000;
          border-bottom: solid 2px #000;
          p{
            margin: 10px 0px;
            font-size: 16px;
          }
        }
        .area-block{
          border-bottom: solid 2px #000;
          p{
            margin: 10px 0px;
            font-size: 16px;
          }
        }
        .intro-block{
          padding: 15px 0px;
          // border: solid 3px red;
          height: 100%;
          .scroll-bar{
            height: 100%;
            // background-color: rgba(0, 0, 0, 0.5);
            // background-color: rgba(0, 0, 0, .8);
            // background-color: #37473B;
            background-color: #1F2823;
            border-radius: 5px;
            p{
              margin: 20px 25px;
              font-size: 16px;
              line-height: 30px;
              color: #fff;
              white-space: pre-line;
            }
          }
          .mobile{
            display: none;
          }
        }
        .scroll-bottom{
          border-bottom: solid 2px #000;
        }
      }
    }
    .start{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 30px;
      .title{
        text-align: center;
        font-size: 5vw;
        letter-spacing: 0px;
        margin-bottom: 40px;
      }
    }
  }
}
.more{
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  .button{
    display: inline-flex;
    background-color: #000;
    padding: 10px 40px;
    align-items: flex-end;
    text-decoration: none;
    p{
      color: #fff;
      font-size: 24px;
      line-height: 24px;
      margin: 0;
      margin-right: 10px;
      letter-spacing: 1px;
    }
    .arrow{
      border-bottom: solid 2px #fff;
      width: 40px;
      border-right: solid 2px #fff;
      height: 12px;
      transform: skewX(30deg);
    }
  }
}
@media (min-width: 1500px) {
  .project{
    .block{
      .project-top{
        img{
          height: 40px;
          margin-right: 5px;
        }
        h2{
          font-size: 24px;
        }
      }
      .project-block{
        .inner-left{
          .title-block{
            p{
              font-size: 16px;
              letter-spacing: 1px;
            }
          }
        }
        .inner-right{
          .type-block{
            p{
              font-size: 16px;
              letter-spacing: 1px;
            }
          }
          .area-block{
            p{
              font-size: 16px;
              letter-spacing: 1px;
            }
          }
          .intro-block{
            .scroll-bar{
              p{
                letter-spacing: 1px;
                font-size: 16px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .project{
    width: 100%;
    .block{
      padding: 0px 5%;
    }
  }
}
@media (max-width: 830px) {
  .project{
    .block{
      max-height: none;
      height: auto;
      .project-block{
        flex-direction: column;
        height: auto;
        .inner-left{
          margin-right: 0px;
          .border-top{
            height: 9px !important;
          }
          .border-bottom{
            height: 7px !important;
            background-color: #000;
          }
          .q-responsive{
            display: block;
            height: 100%;
            :deep() .q-responsive__content{
              display: flex;
              flex-direction: column;
            }
          }
          .border-block{
            display: none;
          }
          .img-block{
            height: calc(100% - 60px);
            margin: 0;
            background-color: #000;
            padding: 0px 9px 0px 9px;
            img{
              margin-bottom: 3px;
            }
            img:last-child{
              margin-bottom: 0px;
            }
          }
        }
        .inner-right{
          .intro-block{
            height: auto;
            .scroll-bar{
              display: none;
            }
            .mobile{
              display: block;
              background-color: #1F2823;
              // background-color: rgba(0, 0, 0, 0.5);
              border-radius: 5px;
              padding: 20px 20px;
              p{
                font-size: 16px;
                line-height: 30px;
                color: #fff;
                white-space: pre-line;
              }
            }
          }
        }
      }
    }
  }
}
</style>
