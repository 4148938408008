import { render } from "./HomeProjectCarousel.vue?vue&type=template&id=3cbb17d2&lang=pug"
import script from "./HomeProjectCarousel.vue?vue&type=script&lang=js"
export * from "./HomeProjectCarousel.vue?vue&type=script&lang=js"

import "./HomeProjectCarousel.vue?vue&type=style&index=0&id=3cbb17d2&lang=scss"
script.render = render

export default script
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCarousel,QCarouselSlide});
