<template lang="pug">
.home
  HomeMain(v-if="!isMobile")
  MobileMain
  MobileContent
  HomeService
  //- HomeProjects
  .to-form
    router-link(to="/contact" aria-label="refresh_your_lifestyle")
      h4 REFRESH&ensp;YOUR&ensp;LIFESTYLE
      .start
        p START NOW
        .arrow
  //- HomeForm
</template>

<script>
import HomeMain from '../components/home/HomeMain.vue'
import MobileMain from '../components/mobile-home/MobileMain.vue'
import MobileContent from '../components/mobile-home/MobileContent.vue'
import HomeService from '../components/home/HomeService.vue'
import HomeProjects from '../components/home/HomeProjects.vue'
import { useMeta } from 'quasar';
// import HomeForm from '../views/HomeForm.vue'
export default {
  name: 'Home',
  components: {
    HomeMain,
    MobileMain,
    MobileContent,
    HomeService,
    HomeProjects
    // HomeForm
  },
  data () {
    return {
      isMobile: false
      // reRender: 0
      // homeMainVisible: true
    }
  },
  created () {
    const that = this
    function isMobileDevice() {
      // const mobileDevice = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone']
      // let isMobileDevice = mobileDevice.some(e => navigator.userAgent.match(e))
      // return isMobileDevice
      const windowWidth = window.innerWidth
      console.log(windowWidth)
      if(windowWidth <= 1024){
        that.isMobile = true
      }
    }
    isMobileDevice()
  },
  beforeMount () {
    // window.scrollTo(0, 0)
  },
  mounted () {
    this.$store.commit('LOADINGANIMATION', true)
    // window.addEventListener('resize', () => {
    //   this.reRender++
    // })
  },

  unmounted () {
  },
  setup(){
    useMeta(() => ({
      title: '首頁 | Woosha Design 舞夏設計',
      meta: {
        description: { name: 'description', content: '首頁 | Woosha Design 舞夏設計'}
      }
      // meta: {
      //   description: { name: 'description', content: '' }
      // }
    }))
  }
}
</script>
<style lang="scss" scoped>
.to-form{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px 20px 20px;
  margin-bottom: 40px;
  a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    h4{
      margin: 0;
      font-size: 48px;
      padding: 15px 40px 15px 40px;
      background-color: #000;
      line-height: 4vw;
    }
    .start{
      display: inline-flex;
      align-items: flex-end;
      background-color: #000;
      padding: 0px 40px 15px 40px;
      p{
        margin: 0;
        margin-right: 10px;
        font-size: 48px;
      }
      .arrow{
        border-bottom: solid 2px #fff;
        width: 40px;
        border-right: solid 2px #fff;
        height: 12px;
        transform: translateY(-14px) skewX(30deg);
      }
    }
  }
}
@media (max-width: 1024px){
  .to-form{
    padding: 30px 5%;
    a{
      width: 100%;
      h4{
        width: 100%;
        font-size: 6.8vw;
        padding: 10px 0;
        background-color: rgba(0, 0, 0, 0);
        color: #000;
        letter-spacing: -1px;
        text-align: center;
        margin-bottom: 20px;
      }
      .start{
        width: 100%;
        padding: 10px 30px;
        display: flex;
        justify-content: center;
        p{
          font-size: 6.8vw;
        }
      }
    }
  }
}

@media (max-width: 400px){
  .to-form{
    a{
      h4{
        font-size: 6.5vw;
      }
      .start{
        p{
          font-size: 6.5vw;
        }
      }
    }
  }
}
</style>
