<template lang="pug">
div.loading
  img(src="@/assets/rolling.svg")
  
</template>
    
<script>
import Rolling from '../assets/rolling.gif'
export default {
  name: 'Rolling',
  components: {
    Rolling
  }
}
</script>
<style lang="scss" scoped>
.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // min-height: calc(100vh - 80px);
  top: 50px;
  min-height: calc(100vh - 50px);
  padding: 0px 5%;
  position: fixed;
  width: 100vw;
  background-color: #4e6554;
  z-index: 999;
  img{
    width: 80px;
    height: 80px;
  }
}
  
</style>