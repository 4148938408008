import { render } from "./MobileContent.vue?vue&type=template&id=72eaf22b&lang=pug"
import script from "./MobileContent.vue?vue&type=script&lang=js"
export * from "./MobileContent.vue?vue&type=script&lang=js"

import "./MobileContent.vue?vue&type=style&index=0&id=72eaf22b&lang=scss"
script.render = render

export default script
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCarousel,QCarouselSlide});
