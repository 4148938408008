import { createApp } from 'vue'
// import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

// const pinia = createPinia()

// import './styles/normal.scss'

createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).mount('#app')
// createApp(App).use(Quasar, quasarUserOptions).use(router).use(pinia).mount('#app')
