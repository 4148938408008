<template lang="pug">
.form
  .header-bg
  .logo-area
    img(src="@/assets/contact-logo.svg" alt="woosha_logo")
  .block
    .title
      img(src="@/assets/move.svg" alt="woosha_logo")
      h2.form-name Task Request Form｜空間設計委託需求表
    article.intro
      p 若有任何疑問，非常歡迎留下訊息給我們，我們將會盡快的為您服務。
    .form-block
      q-form(@submit="onSubmit" @reset="onReset")
        .form-area
          .form-row
            .form-item
              q-input(v-model="name" label="聯絡人姓名 Name" lazy-rules :rules="[ val => val && val.length > 0 || '此為必填欄位']" label-color="white" :input-style="{fontSize: '15px'}")
                template(v-slot:prepend)
                  .box
            .form-item
              q-select(v-model="gender" :options="options" label="稱呼 Gender" :rules="[ val => val && val.length > 0 || '此為必填欄位']" label-color="white" :input-style="{fontSize: '15px'}")
                template(v-slot:prepend)
                  .box
          .form-row
            .form-item.phone
              //- q-input(v-model="phone" label="聯絡電話 Phone" lazy-rules :rules="[ val => val && val.length > 0 || '此為必填欄位', val => validatePhone(val) &&  val.length > 0  || '請輸入正確的電話格式' ]" label-color="white" :input-style="{fontSize: '15px'}")
              //-   template(v-slot:prepend)
              //-     .box
              q-input(v-model="phone" label="聯絡電話 Phone" label-color="white" :input-style="{fontSize: '15px'}")
                template(v-slot:prepend)
                  .box
            .form-item
              q-input(v-model="mail" label="聯絡電子郵件 Mail" lazy-rules :rules="[ val => val && val.length > 0 || '此為必填欄位', val => validateMail(val) &&  val.length > 0  || '請輸入正確的 Mail 格式' ]" label-color="white" :input-style="{fontSize: '15px'}")
                template(v-slot:prepend)
                  .box
          .form-row
            .form-item
              q-select(v-model="caseType" :options="types" label="商業委託類別 Type" :rules="[ val => val && val.length > 0 || '此為必填欄位']" label-color="white" :input-style="{fontSize: '15px'}")
                template(v-slot:prepend)
                  .box
            .form-item
              //- .text-title
              //-   .box
              //-   h4 需求概略描述 Requirement
              q-input(v-model="require" lazy-rules :rules="[ val => val && val.length > 0 || '此為必填欄位']" label="需求概略描述 Requirement" type="textarea" autogrow :input-style="{fontSize: '15px'}")
                template(v-slot:prepend)
                  .box
          .form-row
            .form-item
              q-input(v-model="other" label="其他 Other" type="textarea" autogrow :input-style="{fontSize: '15px'}")
                template(v-slot:prepend)
                  .box
        .btn-block
          q-btn(label="Submit 提交" type="submit" color="black" @submit="onSubmit" aria-label="submit_form")
    .title
      img(src="@/assets/move.svg" alt="woosha_logo")
      h2.form-name Contact Us | 聯絡我們
    .social-area
      a.fb.social-box(:href="getFooterData.facebook" target="_blank" aria-label="fb")
        img(src="@/assets/fb.svg" alt="fb_logo")
      a.ig.social-box(:href="getFooterData.ig" target="_blank" aria-label="ig")
        img(src="@/assets/ig.svg" alt="ig_logo")
      a.line.social-box(:href="getFooterData.line" target="_blank" aria-label="line")
        img(src="@/assets/line.svg" alt="line_logo")
      a.phone.social-box(:href="`tel:${getFooterData.phone}`" target="_blank" aria-label="phone")
        img(src="@/assets/phone.svg" alt="phone_logo")
  q-dialog(v-model='persistent' persistent='' transition-show='scale' transition-hide='scale')
    q-card.bg-teal.text-white(style='width: 300px')
      q-card-section
        .text-h6 Persistent
      q-card-section.q-pt-none
        | Click/Tap on the backdrop.
      q-card-actions.bg-white.text-teal(align='right')
        q-btn(flat='' label='OK' v-close-popup='' aria-label="ok")
</template>

<script>
import { postRequestForm } from '@/api/api'
import { useMeta } from 'quasar';
export default {
  name: 'HomeForm',
  data () {
    return {
      gender: '',
      caseType: '',
      name: '',
      phone: '',
      mail: '',
      options: [
        '先生',
        '小姐',
        '其他'
      ],
      types: [
        '商業空間',
        '辦公空間',
        '住家空間',
        '自地自建'
      ],
      require: '',
      other: '',
      persistent: false
    }
  },
  watch: {
    phone: {
      handler: function () {
        this.phone = this.phone.replace(/[^\d]/g, '')
      }
    }
  },
  computed: {
    getFooterData () {
      return this.$store.state.webContent.footer.socialMedia
    }
  },
  methods: {
    validatePhone (value) {
      const regex1 = /^\d{2,3}\d{3,4}\d{4}$/
      const regex2 = /^09\d{2}\d{3}\d{3}$/gm
      return regex1.test(value) || regex2.test(value)
    },
    validateMail (value) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regex.test(value)
    },
    onSubmit () {
      const that = this
      const submitData = {
        name: this.name,
        salutation: this.gender,
        phone: this.phone,
        email: this.mail,
        spaceType: this.caseType,
        requirementWebsiteDescription: this.require,
        other: this.other
      }

      postRequestForm(submitData).then((result) => {
        that.$q.notify({
          position: 'center',
          color: 'negative',
          textColor: 'black',
          message: '成功寄送表單',
          actions: [
            { label: '確定', color: 'black', bgColor: 'white', handler: () => { window.location.reload() } }
          ]
        })
      }).catch((err) => {
        that.$q.notify({
          position: 'center',
          color: 'negative',
          textColor: 'black',
          message: '表單傳送失敗，請再試一次',
          actions: [
            { label: '確定', color: 'black', bgColor: 'white', handler: () => {} }
          ]
        })
        console.log('[ postRequestForm ] err : ', err.response)
      })
      console.log(submitData)
    }
  },
  mounted () {
    this.$store.commit('LOADINGANIMATION', true)
  },
  setup(){
    useMeta(() => ({
      title: '需求表 | Woosha Design 舞夏設計',
      // description: '需求表 | Woosha Design 舞夏設計'
      meta: {
        description: { name: 'description', content: '需求表 | Woosha Design 舞夏設計' }
      }
    }))
  }
}
</script>
<style lang="scss" scoped>
%clearfix {
	*zoom: 1;
	&:before, &:after {
		display: table;
		line-height: 0;
		content: "";
	}
	&:after {
		clear: both;
	}
}
.form{
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 85px);
  padding: 0px 40px 20px 40px;
  background-color: #fff;
  // :deep() .q-notification{
  //   border: solid 3px red !important;
  // }
  .header-bg{
    height: 57px;
  }
  .logo-area{
    width: 100%;
    background-color: #000;
    display: flex;
    justify-content: center;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 3px;
    display: none;
    img{
      max-width: 90%
    }
  }
  .block{
    position: relative;
    display: flex;
    flex-direction: column;
    // background-color: $green;
    background-color: #fff;
    .social-area{
      display: flex;
      gap: 15px;
      margin: 10px 0px 20px 0px;
      padding-left: 4px;
      .social-box{
        background-color: #000;
        display: inline-flex;
        padding: 8px;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 3px;
      }
    }
    .title{
      width: 100%;
      // padding: 30px 0px 10px 0px;
      margin-top: 30px;
      margin-bottom: 20px;
      @extend %clearfix;
      img{
        height: 23px;
        width: 23px;
        margin-right: 8px;
        float: left;
        margin-top: 5px;
      }
      h2{
        color: #000;
        font-size: 20px;
        margin: 0;
        line-height: 30px;
      }
    }
    .intro{
      display: flex;
      margin-bottom: 0px;
      justify-content: center;
      flex-direction: column;
      p{
        // padding-right: 10%;
        color: #000;
        margin-bottom: 20px;
        // letter-spacing: 1px;
        font-size: 14px;
      }
    }
    .form-block{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: stretch;
      margin-bottom: 0px;
      background-color: #1F2823;
      border-radius: 1px;
      padding-top: 10px;
      padding-bottom: 20px;
      form{
        width: 100%;
        .form-area{
          width: 100%;
          padding: 20px 20px 30px 20px;
          display: flex;
          flex-direction: column;
          .form-row{
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 5px 0px;
            .form-item{
              flex: 1;
              margin: 0px 15px;
              .q-field{
                :deep() .q-field__inner{
                  .q-field__control{
                    .q-field__native{
                      color: #fff;
                    }
                    &::before{
                      border-bottom: solid 1px #fff;
                    }
                    i{
                      color: #fff;
                    }
                  }
                  .q-field__label{
                    color: #fff;
                    font-size: 15px;
                  }
                  .q-field__bottom{
                    color: #fff;
                  }
                }
              }
              .q-textarea{
                .q-field__control{
                  background: rgba(255, 255, 255, 0.2) !important;
                }
              }
              // .text-title{
              //   display: flex;
              //   justify-content: flex-start;
              //   align-items: center;
              //   margin-bottom: 10px;
              //   .box{
              //     margin-right: 10px;
              //   }
              //   h4{
              //     font-size: 14px;
              //     color: #fff;
              //     margin: 0;
              //   }
              // }
            }
          }
          // .form-row:nth-child(3){
          //   padding-bottom: 20px;
          // }
        }
        .btn-block{
          padding: 0px 35px 20px 35px;
        }
      }
    }
    .q-btn{
      font-size: 16px;
    }
  }
  .box{
    height: 20px;
    width: 20px;
    background-color: #fff;
  }
}
@media (min-width: 1500px) {
  .form{
    .block{
      // .title{
      //   img{
      //     height: 40px;
      //     margin-right: 10px;
      //   }
      //   h2{
      //     font-size: 24px;
      //   }
      // }
      .form-block{
        form{
          .form-row{
            .form-item{
              .q-field{
                .q-field__inner{
                  // .q-field__control{
                  //   .q-field__native{
                  //   }
                  //   &::before{
                  //   }
                  //   i{
                  //   }
                  // }
                  .q-field__label{
                    letter-spacing: 1px;
                    font-size: 16px;
                  }
                }
              }
              .text-title{
                .box{
                  margin-right: 15px;
                }
                h4{
                  font-size: 14px;
                  letter-spacing: 1px;
                }
              }
            }
          }
        }
      }
      .btn-block{
        .q-btn{
          letter-spacing: 1px;
        }
      }
    }
    .box{
      height: 20px;
      width: 20px;
      background-color: #fff;
      margin-right: 5px;
    }
  }
}
@media (max-width: 1024px) {
  .form{
    padding: 0px 5% 20px 5%;
  }
  .form-block{
    padding: 0 !important;
  }
  .form-area{
    padding: 10px 10px 20px 10px !important;
  }
  .btn-block{
    padding: 0px 25px 25px 25px !important;
  }
}
@media (max-width: 620px) {
  .form{
    .block{
      .form-block{
        form{
          .form-area{
            .form-row{
              flex-direction: column;
              margin: 0;
            }
            .phone{
              margin: 0px 15px 20px 15px !important;
            }
          }
        }
      }
    }
  }
}
</style>
