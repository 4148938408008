<template lang="pug">
.mobile-content
  .home-intro
    h4.title ABOUT WOOSHA 關於舞夏
    p.content {{ getAboutData[0] }}
    router-link.button(to="/about" aria-label="about_us") About Us
  .home-service
    h4.title WOOSHA SERVICE 服務項目
    p.content {{ getServiceIntro }}
    .service-block
      .type(v-for="(type, index) in getServiceData" key="index")
        .name {{ type.title }}
        .img-block
          HomeCarousel(:parent-msg="type")
          //- q-carousel(v-model="slide" swipeable animated infinite :autoplay="autoplay" transition-prev="slide-right" transition-next="slide-left")
          //-   q-carousel-slide(:name="i" class="column no-wrap flex-center" v-for="(image, i) in type.images" key="i")
          //-     img(:src="image")
  //- .home-project
  //-   h4.title WOOSHA PROJECTS 專案案例
  //-   .mobile-project(v-for="(project, index) in getProjectData" key="index")
  //-     .name-block
  //-       .square
  //-       .name Name | {{ project.name === '' ? project.enName : project.name }}
  //-     HomeProjectCarousel(:parent-msg="project")
  //-     //- q-carousel(v-model="projectSlide" swipeable animated infinite :autoplay="autoplay" transition-prev="slide-right" transition-next="slide-left")
  //-     //-   q-carousel-slide(:name="i" class="column no-wrap flex-center" v-for="(image, i) in project.images" key="i")
  //-     //-     img(:src="image")
  //-     .category
  //-       .type-block
  //-         .square
  //-         .type {{ project.style[0].tw }}
  //-       .area-block
  //-         .square
  //-         .area {{ project.county }}
  //-     .intro
  //-       p(v-html="project.description" v-if="project.description !== ''")
  //-       p(v-else) {{ project.name }}
  //-   //- .more
  //-   //-   router-link.button(to="/projects")
  //-   //-     p ALL PROJECTS
  //-   //-     .arrow
  //-   //- .start
  //-   //-   .title REFRESH YOUR LIFESTYLE
  //-   //-   .more
  //-   //-     router-link.button(to="/projects")
  //-   //-       p START NOW
  //-   //-       .arrow
</template>

<script>
// import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)
import HomeCarousel from './HomeCarousel.vue'
import HomeProjectCarousel from './HomeProjectCarousel.vue'
export default {
  name: 'MobileContent',
  components: {
    HomeCarousel,
    HomeProjectCarousel
  },
  data () {
    return {
      autoplay: true,
      slide: 0,
      projectSlide: 0,
      types: [
        {
          name: '商業 ・COMMERCIAL・',
          images: ['/images/service/商1.jpg', '/images/service/商2.jpg', '/images/service/商3.jpg']
        },
        {
          name: '辦公空間 ・OFFICE・',
          images: ['/images/service/辦1.jpg', '/images/service/辦2.jpg', '/images/service/辦3.jpg']
        },
        {
          name: '住家空間 ・RESIDENCE・',
          images: ['/images/service/住1.jpg', '/images/service/住2.jpg', '/images/service/住3.jpg']
        },
        {
          name: '建築 ・ARCHITECTURE・',
          images: ['/images/service/建1.jpg', '/images/service/建2.jpg', '/images/service/建3.jpg']
        }
      ],
      projects: [
        {
          name: '健身時代',
          type: '商業空間設計',
          area: '台中大里',
          images: ['/images/project1-1.jpeg', '/images/project1-2.jpeg', '/images/project1-3.jpeg'],
          intro: '空間基底用沉穩的色調<br>再用企業色和輔助圖型點綴<br>讓其整體空間成熟中帶活力振奮的感覺<br>-<br>空間內有一個主視覺牆<br>像是岩壁鑿出來的字樣<br>象徵一個好的身形<br>要用時間和毅力<br>加上正確的健身觀念<br>來一點一滴雕塑<br>-<br>這是一個只要用心就能實現夢想的健身時代'
        },
        {
          name: '健身時代2',
          type: '商業空間設計',
          area: '台中大里',
          images: ['/images/project1-3.jpeg', '/images/project1-2.jpeg', '/images/project1-1.jpeg'],
          intro: '空間基底用沉穩的色調<br>再用企業色和輔助圖型點綴<br>讓其整體空間成熟中帶活力振奮的感覺<br>-<br>空間內有一個主視覺牆<br>像是岩壁鑿出來的字樣<br>象徵一個好的身形<br>要用時間和毅力<br>加上正確的健身觀念<br>來一點一滴雕塑<br>-<br>這是一個只要用心就能實現夢想的健身時代'
        }
      ]
    }
  },
  computed: {
    getAboutData () {
      return this.$store.state.webContent.mainInfos
    },
    getServiceIntro () {
      return this.$store.state.webContent.serviceDescription
    },
    getServiceData () {
      return this.$store.state.webContent.serviceSections
    },
    getProjectData () {
      const list = [
        this.$store.state.projects.find(j => j.style.some(k => k.en.toUpperCase() === 'COMMERCIAL')),
        this.$store.state.projects.find(j => j.style.some(k => k.en.toUpperCase() === 'RESIDENCE')),
        this.$store.state.projects.find(j => j.style.some(k => k.en.toUpperCase() === 'OFFICE')),
        this.$store.state.projects.find(j => j.style.some(k => k.en.toUpperCase() === 'ARCHITECTURE'))
      ].filter(e => e)
      return list
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss">
.mobile-content{
  display: none;
}
@media (max-width: 1024px) {
  .mobile-content{
    display: block;
    padding: 5% 5%;
    .home-intro{
      .content{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
    .home-intro, .home-service, .home-project{
      margin: 10px 0px 40px 0px;
      .title{
        font-size: 20px;
        background-color: #000;
        color: #fff;
        padding: 0px 15px;
        display: inline-block;
        margin: 0;
        margin-top: 20px;
        margin-bottom: 25px;
        letter-spacing: 1px;
      }
      .content{
        margin: 0;
        font-size: 16px;
        letter-spacing: 1px;
        margin-bottom: 20px;
      }
      .button{
        color: #fff;
        display: inline-block;
        // margin: 15px 0px;
        font-size: 18px;
        text-decoration: none;
        border-bottom: solid 2px #fff;
        letter-spacing: 1px;
      }
      .service-block{
        margin-top: 40px;
        .type{
          margin-bottom: 60px;
          .name{
            font-size: 20px;
            margin-bottom: 10px;
          }
          .img-block{
            .q-carousel{
              height: auto !important;
              .q-carousel__slide{
                padding: 0 !important;
              }
            }
            img{
              width: 100%;
              pointer-events: none;
            }
          }
        }
        .type:last-child{
          margin-bottom: 0px;
        }
      }
      .mobile-project{
        margin-top: 20px;
        margin-bottom: 50px;
        .name-block{
          border-top: solid 2px #000;
          border-bottom: solid 2px #000;
          margin-bottom: 15px;
          padding: 5px 0px;
          font-size: 18px;
          display: flex;
          align-items: center;
          .square{
            width: 20px;
            height: 20px;
            background-color: #000;
            margin-right: 10px;
          }
        }
        .q-carousel{
          height: 65vw;
          .q-carousel__slide{
            background-color: #000;
            padding: 20px;
          }
          img{
            height: 100%;
          }
        }
        .category{
          margin-top: 15px;
          .type-block{
            border-top: solid 2px #000;
            border-bottom: solid 2px #000;
            padding: 5px 0px;
            font-size: 18px;
            display: flex;
            align-items: center;
            .square{
              width: 20px;
              height: 20px;
              background-color: #000;
              margin-right: 10px;
            }
          }
          .area-block{
            border-bottom: solid 2px #000;
            margin-bottom: 15px;
            padding: 5px 0px;
            font-size: 18px;
            display: flex;
            align-items: center;
            .square{
              width: 20px;
              height: 20px;
              background-color: #000;
              margin-right: 10px;
            }
          }
        }
        .intro{
          white-space: pre-line;
          border-bottom: solid 2px #000;
          font-size: 15px;
          line-height: 26px;
        }
      }
      .mobile-project:last-child{
        margin-bottom: 0px;
      }
    }
    .home-service{
      margin-bottom: 0px;
    }
    .home-project{
      display: none;
      margin-bottom: 10px;
    }
  }
}
</style>
