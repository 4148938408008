<template lang="pug">
q-layout(view="lHh Lpr lFf")
  Header
  #top
  MobileHeader
  Loading(:key="getLoadingKey")
  router-view(v-slot="{ Component, route }")
    transition(name="fade" mode="out-in")
      component(:is="Component" :key="route.path")
  a.toTop(href="#top" :class="{show: show}" aria-label="scroll_to_top")
    img(src="@/assets/arrow.svg" alt="arrow")
  Footer
  //- Home
  //- About
  //- .space
  //- Progress
  //- HomeProjects
  //- HomeForm
  //- ProjectList
</template>

<script>

// import { ref } from 'vue'
import Header from './components/Header.vue'
import MobileHeader from './components/MobileHeader.vue'
import Footer from './components/Footer.vue'
import Home from './views/Home.vue'
import Loading from './components/Loading.vue'
import { getWebsite, getArticles, getProjects, getHomeProjects } from '@/api/api'
// import About from './views/About.vue'
// import Service from './views/Service.vue'
// import Progress from './views/Progress.vue'
// import HomeProjects from './views/HomeProjects.vue'
// import HomeForm from './views/HomeForm.vue'
// import ProjectList from './views/ProjectList.vue'

export default {
  name: 'LayoutDefault',

  components: {
    Header,
    MobileHeader,
    Footer,
    Home,
    Loading
    // About,
    // Progress,
    // HomeProjects,
    // HomeForm
    // ProjectList
  },
  beforeMount () {
    // if(document.location.protocol==='http:' && document.location.hostname!=='localhost'){
    //   document.location.href = document.location.href.replace('http','https')
    // }
  },
  mounted () {
    getWebsite().then((result) => {
      this.$store.commit('LOADINGWEBSITE', result)
    }).catch((err) => {
      console.log('[ getWebsite ] err : ', err.response)
    })
    getArticles().then((result) => {
      this.$store.commit('LOADINGARTICLES', result.data.sort((a, b) => {
        return a.dateTime < b.dateTime ? 1 : -1
      }))
    }).catch((err) => {
      console.log('[ getArticles ] err : ', err.response)
    })
    getProjects().then((result) => {
      this.$store.commit('LOADINGPROJECTS', result.data)
    }).catch((err) => {
      console.log('[ getProjects ] err : ', err.response)
    })
    getHomeProjects().then((result) => {
      this.$store.commit('LOADINGHOMEPROJECTS', result.data)
      console.log('data', result.data)
    }).catch((err) => {
      console.log('[ getHomeProjects ] err : ', err.response)
    })
    window.addEventListener('scroll', this.handleScroll)
  },
  data () {
    return {
      show: false
      // leftDrawerOpen: false
    }
  },
  computed: {
    getLoadingKey () {
      return this.$store.state.isLoading
    }
  },
  methods: {
    handleScroll () {
      const scrollDistance = document.documentElement.scrollTop
      if (scrollDistance !== 0) {
        this.show = true
      } else {
        this.show = false
      }
    }
  }
}
</script>
<style lang="scss">
  // .q-layout{
  //   min-height: auto !important;
  // }
  .toTop{
    position: fixed;
    width: 40px;
    height: 40px;
    display: block;
    background-color: #FF9540;
    bottom: 64px;
    right: 20px;
    border-radius: 3px;
    border: solid 1px #fff;
    opacity: 0;
    z-index: 9999;
    img{
      width: 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }
  html{
    scroll-behavior: smooth;
  }
  body{
    font-family: 'Noto Sans HK', sans-serif !important;
    background-color: $green;
    scroll-behavior: smooth;
    // cursor: none;
  }
  // .q-layout{
  //   height: 300vh;
  // }
  // .space{
  //   height: 100vh;
  // }
  .fade-enter-active, .fade-leave-active{
    transition: .3s;
  }
  .fade-enter-from, .fade-leave-to{
    opacity: 0;
  }
  .test{
    height: 100px
  }
  .show{
    opacity: 1;
    transition-duration: .5s;
  }
@media (max-width: 1024px) {
  .toTop{
    width: 30px;
    height: 30px;
    bottom: 64px;
    right: 15px;
  }
}
</style>
