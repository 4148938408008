<template lang="pug">
.about
  AboutIntro
</template>

<script>
import AboutIntro from '../components/about/AboutIntro.vue';
import { useMeta } from 'quasar';

export default {
  name: 'About',
  components: {
    AboutIntro
  },
  mounted () {
    this.$store.commit('LOADINGANIMATION', true)
  },
  setup(){
    useMeta(() => ({
      title: '關於舞夏 | Woosha Design 舞夏設計',
      // description: '關於舞夏 | Woosha Design 舞夏設計'
      meta: {
        description: { name: 'description', content: '關於舞夏 | Woosha Design 舞夏設計' }
      }
    }))
  }
}
</script>
<style lang="scss" scoped>
</style>
