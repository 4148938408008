export function debounce (func, delay = 250) {
  let timer = null
  return () => {
    const context = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(context, args)
    }, delay)
  }
}


export function defaultTo(datum, defaultValue){
  if(datum) return datum;
  return defaultValue;
}