// import { stringifyQuery } from 'vue-router'
import { createStore } from 'vuex'
// import axios from 'axios'

export default createStore({
  state: {
    isLoading: false,
    isAsyncEnd: false,
    webContent: {
      _id: '',
      serviceDescription: '',
      serviceSections: [
        {
          title: '',
          imgUrls: ['', '', '']
        },
        {
          title: '',
          imgUrls: ['', '', '']
        },
        {
          title: '',
          imgUrls: ['', '', '']
        },
        {
          title: '',
          imgUrls: ['', '', '']
        }
      ],
      mainInfos: [
        '',
        ''
      ],
      memberInfo: '',
      memberList: [
        {
          imgUrl: '',
          jobTitle: '',
          name: ''
        },
        {
          imgUrl: '',
          jobTitle: '',
          name: ''
        },
        {
          imgUrl: '',
          jobTitle: '',
          name: ''
        },
        {
          imgUrl: '',
          jobTitle: '',
          name: ''
        },
        {
          imgUrl: '',
          jobTitle: '',
          name: ''
        }
      ],
      affiliateEnterprises: [
        {
          name: '',
          vatNumber: '',
          businessLicense: ''
        }
      ],
      contactText: '',
      footer: {
        contactInfo: [
          {
            label: '',
            value: ''
          }
        ],
        socialMedia: {
          facebook: '',
          instagram: '',
          line: '',
          email: '',
          phone: ''
        }
      }
    },
    articles: [],
    projects: [
      {
        name: '',
        enName: '',
        style: [
          {
            tw: '',
            en: ''
          }
        ],
        country: '',
        description: '',
        imgs: [
          {
            url: '',
            visible: true
          }
        ]
      }
    ],
    homeProjects: [
      {
        name: '',
        enName: '',
        style: [
          {
            tw: '',
            en: ''
          }
        ],
        country: '',
        description: '',
        imgs: [
          {
            url: '',
            visible: true
          }
        ]
      }
    ]
    // posts: [],
    // projects: []
    // test: {},
    // webContent: {
    //   _id: '1',
    //   serviceDescription: '對於空間規劃有著獨到的見解，勇於嘗試各種各樣的施工方式，致力於創造美好的空間，無論是商空、辦公空間、住宅、建築等，從無到有，從設計到監工檢測，給予客戶穩紮的信賴感，是十年累積而成，是只為完工那一瞬呈現於眼前的專業與美好。',
    //   serviceSections: [
    //     {
    //       title: '商業 / COMMERCIAL',
    //       imgUrls: ['/images/service/商1.jpg', '/images/service/商2.jpg', '/images/service/商3.jpg']
    //     },
    //     {
    //       title: '辦公空間 / OFFICE',
    //       imgUrls: ['/images/service/辦1.jpg', '/images/service/辦2.jpg', '/images/service/辦3.jpg']
    //     },
    //     {
    //       title: '住家空間 / RESIDENCE',
    //       imgUrls: ['/images/service/住1.jpg', '/images/service/住2.jpg', '/images/service/住3.jpg']
    //     },
    //     {
    //       title: '建築 / ARCHITECTURE',
    //       imgUrls: ['/images/service/建1.jpg', '/images/service/建2.jpg', '/images/service/建3.jpg']
    //     }
    //   ],
    //   mainInfos: [
    //     '位於台中的舞夏設計，成立於2012年，除了是家多元的室內設計公司也是建築師事務所，不只擁有合格專業的建築師、室內設計專業執照人員，在2020年更邀請到日籍設計，加入舞夏設計團隊，從此，日文也可以做為溝通設計的語言之一。\n\n舞夏設計提供建築、住宅、商業空間設計規劃及工程管理服務，將客戶需求與生活樣態共同考慮，量身打造出獨一無二的設計風格，除了堅持設計更重視施工品質，因此，舞夏擁有專業的施工團隊，擁有多年的施工經驗及默契，面對每個挑戰都能夠勇於突破，並且順利完成業主的託付，將每個專案打造成獨特作品，成功創造同業的差異化 。',
    //     'We Create beautiful and innovation space.\nWoosha Design Refresh your Lifestyle'
    //   ],
    //   memberInfo: '團隊成員由一群年輕、有想法的設計師所組成，包括建築師 、室內設計師 、專業工班 、品牌視覺設計..等等，給您最安心的設計服務。',
    //   memberList: [
    //     {
    //       imgUrl: '/images/1.png',
    //       jobTitle: '總監',
    //       name: '林冠宇 | Gary'
    //     },
    //     {
    //       imgUrl: '/images/2.png',
    //       jobTitle: '設計總監',
    //       name: '楊博勛 | Uto'
    //     },
    //     {
    //       imgUrl: '/images/3.png',
    //       jobTitle: '副總',
    //       name: '王明志 | Ｍick'
    //     },
    //     {
    //       imgUrl: '/images/4.png',
    //       jobTitle: '建築師',
    //       name: '陳彥宏 | Alvis'
    //     },
    //     {
    //       imgUrl: '/images/5.png',
    //       jobTitle: '海外業務經理',
    //       name: '林聯忠 Larry'
    //     }
    //   ],
    //   affiliateEnterprises: [
    //     {
    //       name: '舞夏室內裝修設計股份有限公司',
    //       /** 統一編號 */
    //       vatNumber: '60200755',
    //       /** 字號 */
    //       businessLicense: '內營室業字第40E2008522號'
    //     },
    //     {
    //       name: '築辰建築師事務所',
    //       /** 統一編號 */
    //       vatNumber: '47534218',
    //       /** 字號 */
    //       businessLicense: '中市建開證字第M001137號'
    //     },
    //     {
    //       name: '逸居前端科技檢驗股份有限公司',
    //       /** 統一編號 */
    //       vatNumber: '82830378',
    //       /** 字號 */
    //       businessLicense: ''
    //     }
    //   ],
    //   contactText: '若有任何疑問，非常歡迎留下訊息給我們，我們將會盡快的為您服務。',
    //   footer: {
    //     contactInfo: [
    //       {
    //         label: 'Tel: +886-4-23716688',
    //         value: '+886-4-23716688'
    //       },
    //       {
    //         label: 'info@woosha-design.com',
    //         value: 'info@woosha-design.com'
    //       },
    //       {
    //         label: '40344 台中市西區大全街74號5樓',
    //         value: 'https://www.google.com.tw/maps/place/403%E5%8F%B0%E4%B8%AD%E5%B8%82%E8%A5%BF%E5%8D%80%E5%A4%A7%E5%85%A8%E8%A1%9774%E8%99%9F5%E6%A8%93/@24.1351626,120.6679393,17z/data=!3m1!4b1!4m6!3m5!1s0x34693d08ebd54371:0xce3dbd3582e742a8!8m2!3d24.1351577!4d120.6701333!16s%2Fg%2F11qp_fbvg9?hl=zh-TW&authuser=0'
    //       }
    //     ],
    //     socialMedia: {
    //       facebook: '',
    //       instagram: '',
    //       line: '',
    //       email: 'info@woosha-design.com',
    //       phone: '+886-4-23716688'
    //     }
    //   }
    // },
    // posts: [
    //   {
    //     _id: '0',
    //     title: '文章1',
    //     dateTime: '2022/10/12',
    //     style: [
    //       {
    //         tw: '空間設計',
    //         en: 'test1'
    //       },
    //       {
    //         tw: '施工紀錄',
    //         en: 'test2'
    //       }
    //     ],
    //     content: '<p>業主考量到稅務和法規<br>所以一開始將空間變成三間獨立的空間<br>兩間診所和一間藥局<br>變成每一個空間都很狹隘<br><hr>下圖為裝潢前，診所的樣貌<br><img src=""/><img src=""/>陰暗，且牆面衛教資訊太雜亂<br>空間壓迫感重<br></p>',
    //     imgs: [
    //       {
    //         url: '/test/slide1.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide2.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide3.png',
    //         visible: true
    //       }
    //     ]
    //   },
    //   {
    //     _id: '1',
    //     title: '文章2',
    //     dateTime: '2022/10/13',
    //     style: [
    //       {
    //         tw: '商業空間',
    //         en: 'test3'
    //       },
    //       {
    //         tw: '施工紀錄',
    //         en: 'test2'
    //       }
    //     ],
    //     content: `<p>業主考量到稅務和法規<br>
    //       所以一開始將空間變成三間獨立的空間<br>
    //       兩間診所和一間藥局<br>
    //       變成每一個空間都很狹隘<br>
    //       <hr>
    //       下圖為裝潢前，診所的樣貌<br>
    //       <img src=""/>
    //       <img src=""/>
    //       陰暗，且牆面衛教資訊太雜亂<br>
    //       空間壓迫感重<br>
    //     </p>`,
    //     imgs: [
    //       {
    //         url: '/test/slide2.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide1.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide3.png',
    //         visible: true
    //       }
    //     ]
    //   },
    //   {
    //     _id: '2',
    //     title: '文章3',
    //     dateTime: '2022/10/14',
    //     style: [
    //       {
    //         tw: '旅店',
    //         en: 'test4'
    //       }, {
    //         tw: '室內設計',
    //         en: 'test5'
    //       }
    //     ],
    //     content: `<p>業主考量到稅務和法規<br>
    //       所以一開始將空間變成三間獨立的空間<br>
    //       兩間診所和一間藥局<br>
    //       變成每一個空間都很狹隘<br>
    //       <hr>
    //       下圖為裝潢前，診所的樣貌<br>
    //       <img src=""/>
    //       <img src=""/>
    //       陰暗，且牆面衛教資訊太雜亂<br>
    //       空間壓迫感重<br>
    //     </p>`,
    //     imgs: [
    //       {
    //         url: '/test/slide3.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide2.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide1.png',
    //         visible: true
    //       }
    //     ]
    //   },
    //   {
    //     _id: '3',
    //     title: '文章4',
    //     dateTime: '2022/10/12',
    //     style: [
    //       {
    //         tw: '空間設計',
    //         en: 'test1'
    //       },
    //       {
    //         tw: '施工紀錄',
    //         en: 'test2'
    //       }
    //     ],
    //     content: '<p>業主考量到稅務和法規<br>所以一開始將空間變成三間獨立的空間<br>兩間診所和一間藥局<br>變成每一個空間都很狹隘<br><hr>下圖為裝潢前，診所的樣貌<br><img src=""/><img src=""/>陰暗，且牆面衛教資訊太雜亂<br>空間壓迫感重<br></p>',
    //     imgs: [
    //       {
    //         url: '/test/slide1.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide2.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide3.png',
    //         visible: true
    //       }
    //     ]
    //   },
    //   {
    //     _id: '4',
    //     title: '文章5',
    //     dateTime: '2022/10/12',
    //     style: [
    //       {
    //         tw: '空間設計',
    //         en: 'test1'
    //       }, {
    //         tw: '施工紀錄',
    //         en: 'test2'
    //       }
    //     ],
    //     content: '<p>業主考量到稅務和法規<br>所以一開始將空間變成三間獨立的空間<br>兩間診所和一間藥局<br>變成每一個空間都很狹隘<br><hr>下圖為裝潢前，診所的樣貌<br><img src=""/><img src=""/>陰暗，且牆面衛教資訊太雜亂<br>空間壓迫感重<br></p>',
    //     imgs: [
    //       {
    //         url: '/test/slide1.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide2.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide3.png',
    //         visible: true
    //       }
    //     ]
    //   },
    //   {
    //     _id: '5',
    //     title: '文章6',
    //     dateTime: '2022/10/12',
    //     style: [
    //       {
    //         tw: '空間設計',
    //         en: 'test1'
    //       },
    //       {
    //         tw: '施工紀錄',
    //         en: 'test2'
    //       }
    //     ],
    //     content: '<p>業主考量到稅務和法規<br>所以一開始將空間變成三間獨立的空間<br>兩間診所和一間藥局<br>變成每一個空間都很狹隘<br><hr>下圖為裝潢前，診所的樣貌<br><img src=""/><img src=""/>陰暗，且牆面衛教資訊太雜亂<br>空間壓迫感重<br></p>',
    //     imgs: [
    //       {
    //         url: '/test/slide1.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide2.png',
    //         visible: true
    //       },
    //       {
    //         url: '/test/slide3.png',
    //         visible: true
    //       }
    //     ]
    //   }
    // ]
    // projects: [
    //   {
    //     _id: '0',
    //     name: '健身時代',
    //     enName: 'GYM NERATION',
    //     style: [
    //       {
    //         tw: '商業空間設計',
    //         en: 'COMMERCIAL'
    //       }
    //     ],
    //     country: '台中大里',
    //     description: '空間基底用沉穩的色調\n再用企業色和輔助圖型點綴\n讓其整體空間成熟中帶活力振奮的感覺\n-空間內有一個主視覺牆\n像是岩壁鑿出來的字樣\n象徵一個好的身形\n要用時間和毅力\n加上正確的健身觀念\n來一點一滴雕塑\n-\n這是一個只要用心就能實現夢想的健身時代\n來一點一滴雕塑\n-\n這是一個只要用心就能實現夢想的健身時代',
    //     imgs: [
    //       {
    //         url: '/images/project1-1.jpeg',
    //         visible: true
    //       },
    //       {
    //         url: '/images/project1-2.jpeg',
    //         visible: true
    //       },
    //       {
    //         url: '/images/project1-3.jpeg',
    //         visible: true
    //       }
    //     ]
    //   },
    //   {
    //     _id: '1',
    //     name: '亞丁尼',
    //     enName: 'YADINGNI',
    //     style: [
    //       {
    //         tw: '餐廳設計',
    //         en: 'RESTUARANT'
    //       }
    //     ],
    //     country: '台中美村南路',
    //     description: '亞丁尼工學店坐落於美村南路跟工學路轉角上\n以優雅的綠搭配線板語彙\n在這個繁忙的轉角吸引過路人的眼球\n圓拱的窗花，騎樓的線板\n讓人好似錯置在另一個空間\n店內以企業色綠、藍、黃做搭配\n最後再大量灰色手感漆統合空間調性打造出簡約質感\n而茶鏡及選用玻璃燈具豐富空間層次\n也讓空間視覺更加寬闊\n地坪也藉由不同方向的木紋拼貼，花磚的區塊分界\n讓整體視覺及用餐體驗都增添了趣味性。',
    //     imgs: [
    //       {
    //         url: '/images/project1-3.jpeg',
    //         visible: true
    //       },
    //       {
    //         url: '/images/project1-2.jpeg',
    //         visible: true
    //       },
    //       {
    //         url: '/images/project1-1.jpeg',
    //         visible: true
    //       }
    //     ]
    //   },
    //   {
    //     _id: '2',
    //     name: '亞丁尼',
    //     enName: 'YADINGNI',
    //     style: [
    //       {
    //         tw: '建築設計',
    //         en: 'ARCHITECTURE'
    //       }
    //     ],
    //     country: '台中美村南路',
    //     description: '亞丁尼工學店坐落於美村南路跟工學路轉角上\n以優雅的綠搭配線板語彙\n在這個繁忙的轉角吸引過路人的眼球\n圓拱的窗花，騎樓的線板\n讓人好似錯置在另一個空間\n店內以企業色綠、藍、黃做搭配\n最後再大量灰色手感漆統合空間調性打造出簡約質感\n而茶鏡及選用玻璃燈具豐富空間層次\n也讓空間視覺更加寬闊\n地坪也藉由不同方向的木紋拼貼，花磚的區塊分界\n讓整體視覺及用餐體驗都增添了趣味性。',
    //     imgs: [
    //       {
    //         url: '/images/project1-3.jpeg',
    //         visible: true
    //       },
    //       {
    //         url: '/images/project1-2.jpeg',
    //         visible: true
    //       },
    //       {
    //         url: '/images/project1-1.jpeg',
    //         visible: true
    //       }
    //     ]
    //   },
    //   {
    //     _id: '3',
    //     name: '亞丁尼',
    //     enName: 'YADINGNI',
    //     style: [
    //       {
    //         tw: '辦公室設計',
    //         en: 'OFFICE'
    //       }
    //     ],
    //     country: '台中美村南路',
    //     description: '亞丁尼工學店坐落於美村南路跟工學路轉角上\n以優雅的綠搭配線板語彙\n在這個繁忙的轉角吸引過路人的眼球\n圓拱的窗花，騎樓的線板\n讓人好似錯置在另一個空間\n店內以企業色綠、藍、黃做搭配\n最後再大量灰色手感漆統合空間調性打造出簡約質感\n而茶鏡及選用玻璃燈具豐富空間層次\n也讓空間視覺更加寬闊\n地坪也藉由不同方向的木紋拼貼，花磚的區塊分界\n讓整體視覺及用餐體驗都增添了趣味性。',
    //     imgs: [
    //       {
    //         url: '/images/project1-3.jpeg',
    //         visible: true
    //       },
    //       {
    //         url: '/images/project1-2.jpeg',
    //         visible: true
    //       },
    //       {
    //         url: '/images/project1-1.jpeg',
    //         visible: true
    //       }
    //     ]
    //   },
    //   {
    //     _id: '4',
    //     name: '亞丁尼',
    //     enName: 'YADINGNI',
    //     style: [
    //       {
    //         tw: '餐廳設計',
    //         en: 'RESTUARANT'
    //       }
    //     ],
    //     country: '台中美村南路',
    //     description: '亞丁尼工學店坐落於美村南路跟工學路轉角上\n以優雅的綠搭配線板語彙\n在這個繁忙的轉角吸引過路人的眼球\n圓拱的窗花，騎樓的線板\n讓人好似錯置在另一個空間\n店內以企業色綠、藍、黃做搭配\n最後再大量灰色手感漆統合空間調性打造出簡約質感\n而茶鏡及選用玻璃燈具豐富空間層次\n也讓空間視覺更加寬闊\n地坪也藉由不同方向的木紋拼貼，花磚的區塊分界\n讓整體視覺及用餐體驗都增添了趣味性。',
    //     imgs: [
    //       {
    //         url: '/images/project1-3.jpeg',
    //         visible: true
    //       },
    //       {
    //         url: '/images/project1-2.jpeg',
    //         visible: true
    //       },
    //       {
    //         url: '/images/project1-1.jpeg',
    //         visible: true
    //       }
    //     ]
    //   }
    // ]
  },
  mutations: {
    LOADINGWEBSITE (state, status) {
      state.webContent = status
    },
    LOADINGARTICLES (state, status) {
      state.articles = status
      state.isAsyncEnd = true
    },
    LOADINGPROJECTS (state, status) {
      state.projects = status
      state.isAsyncEnd = true
    },
    LOADINGANIMATION (state, status) {
      state.isLoading = status
    },
    LOADINGHOMEPROJECTS (state, status) {
      state.homeProjects = status
    },
    ISASYNCEND (state, status) {
      state.isAsyncEnd = status
    }
  },
  actions: {
  },
  modules: {
  }
})
