import { render } from "./Form.vue?vue&type=template&id=2e9d993c&scoped=true&lang=pug"
import script from "./Form.vue?vue&type=script&lang=js"
export * from "./Form.vue?vue&type=script&lang=js"

import "./Form.vue?vue&type=style&index=0&id=2e9d993c&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-2e9d993c"

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QField from 'quasar/src/components/field/QField.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QInput,QSelect,QBtn,QDialog,QCard,QCardSection,QCardActions,QField});qInstall(script, 'directives', {ClosePopup});
