import { render } from "./Project.vue?vue&type=template&id=1c6784ad&scoped=true&lang=pug"
import script from "./Project.vue?vue&type=script&lang=js"
export * from "./Project.vue?vue&type=script&lang=js"

import "./Project.vue?vue&type=style&index=0&id=1c6784ad&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1c6784ad"

export default script
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QResponsive from 'quasar/src/components/responsive/QResponsive.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QScrollArea,QResponsive});
