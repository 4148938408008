<template lang="pug">
.home-main
  .header-bg
  .block
    .center
      .middle
        .up
          //- span.up-span(v-for="(word, index) in up" key="index")
          //-   img(:src="word.link")
        .down
          .show-block
            //- span.show-span(v-for="(word, index) in show" key="index")
            //-   img.show-text(:src="word.link")
            span.show-span.d
              img.show-text(src="@/assets/d.svg" alt="d")
            span.show-span.e
              img.show-text(src="@/assets/e.svg" alt="e")
            span.show-span.s
              img.show-text(src="@/assets/s.svg" alt="s")
            span.show-span.i
              img.show-text(src="@/assets/i.svg" alt="i")
            span.show-span.g
              img.show-text(src="@/assets/g.svg" alt="g")
            span.show-span.n
              img.show-text(src="@/assets/n.svg" alt="n")
          span.move-block
            img.move-img(src="@/assets/box.svg" alt="box")
          .text-block
            //- span.down-span(v-for="(word, index) in down" key="index")
            //-   img.animate-text(:src="word.link")
            span.down-span.w
              img.animate-text(src="@/assets/w.svg" alt="w")
            span.down-span.o
              img.animate-text(src="@/assets/o.svg" alt="o")
            span.down-span.o
              img.animate-text(src="@/assets/o.svg" alt="o")
            span.down-span.s
              img.animate-text(src="@/assets/s.svg" alt="s")
            span.down-span.h
              img.animate-text(src="@/assets/h.svg" alt="h")
            span.down-span.a
              img.animate-text(src="@/assets/a.svg" alt="a")
</template>

<script>
// @ is an alias to /src
// import { debounce } from '@/common/utils'
import { debounce } from 'lodash-es'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
let textTl, textTl2, textTl3
export default {
  name: 'HomeMain',
  components: {
  },
  data () {
    return {
      debouncedResize: undefined,
      isLoad: false,
      width: 0,
      // up: [
      //   {
      //     link: '/images/w.svg'
      //   },
      //   {
      //     link: '/images/o.svg'
      //   },
      //   {
      //     link: '/images/o.svg'
      //   },
      //   {
      //     link: '/images/s.svg'
      //   },
      //   {
      //     link: '/images/h.svg'
      //   },
      //   {
      //     link: '/images/a.svg'
      //   }
      // ],
      down: [
        {
          link: '/images/w.svg'
        },
        {
          link: '/images/o.svg'
        },
        {
          link: '/images/o.svg'
        },
        {
          link: '/images/s.svg'
        },
        {
          link: '/images/h.svg'
        },
        {
          link: '/images/a.svg'
        }
      ],
      show: [
        {
          link: '/images/d.svg'
        },
        {
          link: '/images/e.svg'
        },
        {
          link: '/images/s.svg'
        },
        {
          link: '/images/i.svg'
        },
        {
          link: '/images/g.svg'
        },
        {
          link: '/images/n.svg'
        }
      ]
    }
  },
  watch: {
    width: {
      handler: function () {
        if (this.width < 1024) {
          window.removeEventListener('resize', this.handleResize)
        }
      }
    }
  },
  methods: {
    getWindowWidth () {
      this.width = window.innerWidth
    },
    // startAnimate () {
    //   const startTl = gsap.timeline({})
    //   startTl.from('.up-span img', {
    //     opacity: 0,
    //     ease: 'power1.out',
    //     stagger: 0.2
    //   }).from('.down-span .animate-text', {
    //     opacity: 0,
    //     duration: 0.5,
    //     ease: 'power1.out'
    //   }).from('.move-img', {
    //     opacity: 0,
    //     x: -20,
    //     duration: 0.5,
    //     ease: 'power1.out'
    //   }, '<').from('.top .left', {
    //     opacity: 0,
    //     y: -20,
    //     duration: 0.3,
    //     ease: 'power1.out'
    //   }).from('.social .box', {
    //     opacity: 0,
    //     y: -20,
    //     duration: 0.3,
    //     ease: 'power1.out'
    //   }, '<').from('.slogan', {
    //     opacity: 0,
    //     x: -20,
    //     duration: 0.3,
    //     ease: 'power1.out'
    //   }).from('.slogan2', {
    //     opacity: 0,
    //     y: 20,
    //     duration: 0.3,
    //     ease: 'power1.out'
    //   })
    // },
    textAnimate () {
      // block move
      textTl = gsap.timeline({
        scrollTrigger: {
          trigger: '.home-main',
          pin: true,
          start: 'top top',
          end: '+=4000',
          scrub: true
        }
      })
      let count = 0 // 累加圖片個數
      const text = gsap.utils.toArray('.animate-text')
      for (let i = 0; i < text.length; i++) {
        text[i].onload = function () {
          count++ // 如果載入成功累加1
          if (count === text.length) {
            const textsWidth = document.querySelector('.text-block').offsetWidth
            textTl.to('.move-block', {
              transformOrigin: '50% 50%',
              x: textsWidth,
              rotate: 360,
              duration: 1,
              ease: 'none'
            })
          }
        }
      }

      // text move
      const windowHeight = document.querySelector('.home-main').offsetHeight
      console.log('windowHeight', windowHeight)
      textTl2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.home-main',
          pin: true,
          start: 'top top',
          end: '+=4000',
          scrub: true
        }
      })
      const texts = gsap.utils.toArray('.animate-text')
      for (let i = 0; i < texts.length; i++) {
        const index = i % 2
        let move = 0
        if (index === 0) {
          move = -1
        } else {
          move = 1
        }
        textTl2.to(texts[i], {
          y: windowHeight / 1.1 * move,
          x: -100,
          rotate: 90 * move,
          duration: 1,
          ease: 'none'
        })
      }

      // text show
      textTl3 = gsap.timeline({
        scrollTrigger: {
          trigger: '.home-main',
          pin: true,
          start: 'top top',
          end: '+=4000',
          scrub: true
        }
      })
      const showTexts = gsap.utils.toArray('.show-text')
      for (let i = 0; i < showTexts.length; i++) {
        textTl3.to(showTexts[i], {
          opacity: 1,
          duration: 1,
          ease: 'none'
        })
      }
    },
    rightDistance () {
      const that = this
      const moveBlock = document.querySelector('.move-img')
      // let count = 0
      moveBlock.onload = function () {
        that.isLoad = true
        // count += 1
        if (that.isLoad === true) {
          const rightDistance = document.querySelector('.move-block').offsetWidth
          const distance = rightDistance.toString() + 'px'
          document.querySelector('.show-block').style.right = distance
        }
      }
      if (that.isLoad === true) {
        const rightDistance = document.querySelector('.move-block').offsetWidth
        const distance = rightDistance.toString() + 'px'
        document.querySelector('.show-block').style.right = distance
      }
    },
    handleResize () {
      const that = this
      const width = window.innerWidth
      if (width > 1024) {
        window.location.reload()
      }
      that.textAnimate()
      that.rightDistance()
      that.getWindowWidth()
    }
    // handleDebounceProjectResize () {
    //   debounce(this.handleResize, 3000)
    // }
  },
  created () {
    this.debouncedResize = debounce(this.handleResize, 1000)
  },
  mounted () {
    window.addEventListener('resize', this.debouncedResize)
    this.getWindowWidth()
    this.textAnimate()
    this.rightDistance()
  },
  unmounted () {
    textTl.kill()
    textTl2.kill()
    textTl3.kill()
    // console.log(textTl)
    window.removeEventListener('resize', this.debouncedResize)
    this.debouncedResize.cancel
  }
}
</script>
<style lang="scss" scoped>
.home-main{
  background-color: $green;
  height: 100vh;
  overflow: hidden;
  .header-bg{
    height: 57px;
  }
  .block{
    padding: 30px 40px;
    height: calc(100% - 57px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: solid 3px red;
    .center{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .middle{
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        // margin: 2%;
        // max-height: 28vw;
        // max-width: 170vh;
        .up, .down{
          height: 50%;
          width: 100%;
          // border: solid 3px red;
          span{
            height: 100%;
            img{
              height: 100%;
              object-fit: contain;
            }
          }
        }
        .down{
          display: flex;
          justify-content: space-between;
          position: relative;
          .move-block{
            width: 14%;
            img{
              width: 100%;
            }
          }
          .text-block{
            width: 84.5%;
            display: flex;
            position: relative;
            span{
              height: 100%;
              img{
                width: 100%;
              }
            }
            .w{
              width: 21%;
            }
            .o{
              width: 16.5%;
            }
            .s{
              width: 14%;
            }
            .h{
              width: 15%;
            }
            .a{
              width: 17%;
            }
          }
          .show-block{
            width: 72%;
            height: 100%;
            left: 0%;
            display: flex;
            position: absolute;
            span{
              img{
                width: 100%;
                opacity: 0;
              }
            }
            .d{
              width: 18%;
            }
            .e{
              width: 17%;
            }
            .s{
              width: 17%;
            }
            .i{
              width: 6.2%;
            }
            .g{
              width: 23%;
            }
            .n{
              width: 19%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .home-main{
    display: none;
  }
}
</style>
