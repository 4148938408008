<template lang="pug">
q-carousel(v-model="projectSlide" swipeable animated infinite :autoplay="autoplay" transition-prev="slide-right" transition-next="slide-left")
  q-carousel-slide(:name="i" class="column no-wrap flex-center" v-for="(image, i) in parentMsg.imgs" key="i")
    img(:src="image.url")
</template>

<script>

export default {
  name: 'Carousel',
  components: {
  },
  props: [
    'parentMsg'
  ],
  data () {
    return {
      autoplay: true,
      slide: 0,
      projectSlide: 0
    }
  }
}
</script>
<style lang="scss">
.q-carousel{
  height: 65vw;
  .q-carousel__slide{
    background-color: #000;
    padding: 20px;
  }
  img{
    height: 100%;
    pointer-events: none;
  }
}
</style>
