import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Works from '../views/Works.vue'
import Form from '../views/Form.vue'
import Blog from '../views/Blog.vue'
// import Progress from '../views/Progress.vue'
import Post from '../views/Post.vue'
import Project from '../views/Project.vue'
import NoFound from '../views/NoFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/works',
    name: 'Works',
    component: Works
  },
  {
    path: '/contact',
    name: 'Form',
    component: Form
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  // {
  //   path: '/blog/:blogId',
  //   name: 'Blog',
  //   component: Blog
  // },
  // {
  //   path: '/progress',
  //   name: 'Progress',
  //   component: Progress
  // },
  {
    path: '/project/:projectId',
    name: 'Project',
    component: Project
  },
  {
    path: '/post/:postId',
    name: 'Post',
    component: Post
  },
  {
    path: '/noFound',
    name: 'NoFound',
    component: NoFound
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/noFound'
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   if (to.hash) {
  //     return {
  //       selector: to.hash
  //     }
  //   }
  // }
  scrollBehavior (to, from) {
    if (to.hash) {
      return { el: `#${to.hash}`, behavior: 'smooth' }
    }
    if (from.name && to && to.name === 'Home') {
      // console.log('from', from)
      window.location.reload()
    }
    return { top: 0, behavior: 'smooth' }
  }
})

export default router
