<template lang="pug">
transition(name="mask")
  .loading(v-if="isLoading" ref="loading")
    .background
    .middle-space(:class="bgAnStart ? 'background-animation' : ''" @animationend.self="startDisappear")
      .logo
        <svg viewBox="0 0 148 149" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="147" height="147" stroke="black" stroke-width="7" />
          <path d="M32.1875 0.5625H90.1406L48.5892 56H32.1875V0.4375" stroke="black" stroke-width="5" />
          <path d="M0.5 56H32.1948V95.6072H79.5821L147.5 0.5V148H0.5V56Z" stroke="black" stroke-width="5" />
        </svg>
      .word-block
        .en-block
          img(v-for="(word, i) in wordList" :src="require(`@/assets/${word.url}`)" :class="animationClass()" :style="animationStyle(i, wordList.length)" @animationend.stop="startHide(i, wordList.length)")
        .ch-block
          img(v-for="(word, i) in chWordList" :src="require(`@/assets/${word.url}`)" :class="animationClass()" :style="animationStyle(i, wordList.length)" @animationend.stop)
    .slogan(:class="bgAnStart ? 'background-animation' : ''") REFRESH&ensp;YOUR&ensp;LIFESTYLE
</template>
<script>

export default {
  name: 'Loading',
  data () {
    return {
      reStartKey: 0,
      boxEnd: false,
      bgAnStart: false,
      state: {
        isEntering: false,
        isLeaving: false
      },
      wordList: [
        {
          url: 'en-w.svg'
        },
        {
          url: 'en-o.svg'
        },
        {
          url: 'en-o.svg'
        },
        {
          url: 'en-s.svg'
        },
        {
          url: 'en-h.svg'
        },
        {
          url: 'en-a.svg'
        }
      ],
      chWordList: [
        {
          url: 'word-01.svg'
        },
        {
          url: 'word-02.svg'
        },
        {
          url: 'word-03.svg'
        },
        {
          url: 'word-04.svg'
        }
      ]
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.isLoading
    }
  },
  watch: {
    isLoading: {
      handler: function () {
        console.log('test')
        this.$nextTick(function () {
          this.getVh()
        })
        // this.getVh()
      }
    }
  },
  methods: {
    animationClass () {
      return 'loading-animation'
    },
    animationStyle (index, arrayLength) {
      return {
        'animation-duration': '0.1s',
        'animation-delay': String(index * 0.1) + 's'
      }
    },
    startHide (index, arrayLength) {
      if (index + 1 === arrayLength) {
        this.bgAnStart = true
      }
    },
    startDisappear () {
      setTimeout(() => {
        this.$store.commit('LOADINGANIMATION', false)
      }, 100)
    },
    getVh () {
      if (document.querySelector('.loading') !== null) {
        const vh = window.innerHeight * 0.01
        document.querySelector('.loading').style.setProperty('--vh', `${vh}px`)
      }
    }
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
.mask-enter-active, .mask-leave-active{
  transition: .4s;
}
.mask-enter-from{
  opacity: 1;
}
.mask-leave-to{
  opacity: 0;
}

.box-end-class{
  position: relative !important;
}
@keyframes bgAnimate {
  0% {
    opacity: 1;
  }
  50%{
    opacity: 0.6;
  }
  100%{
    opacity: 0;
  }
}
@keyframes offset {
  to {
    stroke-dashoffset: 100;
  }
}
@keyframes loading {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  25% {
    opacity: .4;
  }
  50% {
    opacity: .6;
  }
  75%{
    opacity: .8;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

// @keyframes box {
//   0% {
//     left: 50%;
//     transform: translateX(-50%) rotate(360deg);
//     opacity: 0;
//   }
//   100% {
//     left: 0%;
//     transform: translateX(0%) rotate(0deg);
//     opacity: 1;
//   }
// }
.loading-animation{
  animation: loading ease-in;
  animation-fill-mode: forwards;
}
.background-animation{
  animation: bgAnimate .5s ease-in;
  animation-fill-mode: forwards;
}
.loading{
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .background{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: #4e6554;
  }
  .test{
    z-index: 1000;
  }
  .top-space{
    height: 100px;
  }
  .middle-space{
    display: flex;
    align-items: center;
    position: relative;
    width: 720px;
    .logo{
      margin-right: 20px;
      // height: 100%;
      width: 150px;
      height: 150px;
      // animation: box 1s ease-in;
      // animation-fill-mode: forwards;
      svg{
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        height: 100%;
        rect{
          animation: offset 1s linear;
          animation-fill-mode: forwards;
        }
        path{
          animation: offset 1s linear;
          animation-fill-mode: forwards;
        }
      }
    }
    .word-block{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .en-block{
        display: flex;
        justify-content: center;
        height: 85px;
        img{
          opacity: 0;
          height: 100%;
          margin: 0px 1px;
        }
      }
      .ch-block{
        display: flex;
        justify-content: center;
        height: 35px;
        margin-top: 30px;
        img{
          opacity: 0;
          height: 100%;
          margin-right: 15px;
        }
      }
    }
  }
  .slogan{
    position: absolute;
    margin-top: 50px;
    font-size: 40px;
    font-weight: 900;
    letter-spacing: 0px;
    bottom: 40px;
  }
}

@media (max-width: 820px) {
  .loading{
    .middle-space{
      width: 530px;
      .logo{
        width: 125px;
        height: 125px
      }
      .word-block{
        .en-block{
          height: 60px;
        }
      }
    }
  }
}
@media (max-width: 620px) {
  .loading{
    .middle-space{
      width: 380px;
      .logo{
        width: 105px;
        height: 105px
      }
      .word-block{
        .en-block{
          height: 40px;
        }
        .ch-block{
          height: 35px;
        }
      }
    }
    .slogan{
      font-size: 30px;
    }
  }
}
@media (max-width: 460px) {
  .loading{
    .middle-space{
      width: 300px;
      .logo{
        width: 70px;
        height: 70px;
      }
      .word-block{
        .en-block{
          height: 30px;
        }
        .ch-block{
          height: 25px;
          margin-top: 15px;
        }
      }
    }
    .slogan{
      font-size: 22px;
    }
  }
}
</style>
