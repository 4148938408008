import { render } from "./HomeProjects.vue?vue&type=template&id=ac372e6c&scoped=true&lang=pug"
import script from "./HomeProjects.vue?vue&type=script&lang=js"
export * from "./HomeProjects.vue?vue&type=script&lang=js"

import "./HomeProjects.vue?vue&type=style&index=0&id=ac372e6c&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-ac372e6c"

export default script
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QScrollArea});
